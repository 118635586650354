import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

@autoinject()
export class LockedBySignedReportReportIdsComputer extends ValueComputer<
  Record<string, never>,
  LockedBySignedReportReportIdsComputerValue
> {
  private readonly subscriptionManager: SubscriptionManager;

  private limitToOneSignedReport = true;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToMultipleModelChanges(
      [EntityName.Report],
      () => {
        invokeCompute();
      }
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'pdfSigning.limitToOneSignedReport',
        (disableExportOfProjectsWithSignedReports) => {
          this.limitToOneSignedReport =
            disableExportOfProjectsWithSignedReports;
          invokeCompute();
        }
      )
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): LockedBySignedReportReportIdsComputerValue {
    const value: LockedBySignedReportReportIdsComputerValue = {
      lockedSigningReportIds: new Set()
    };
    if (this.limitToOneSignedReport) {
      const projects = this.entityManager.projectRepository.getAll();
      for (const project of projects) {
        const reports = this.entityManager.reportRepository.getByProjectId(
          project.id
        );
        if (reports.some((r) => r.signatureTime != null)) {
          for (const report of reports) {
            value.lockedSigningReportIds.add(report.id);
          }
        }
      }
    }
    return value;
  }
}

export type LockedBySignedReportReportIdsComputerValue = {
  lockedSigningReportIds: Set<string>;
};
