import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {CustomRadioButtonCheckedChanged} checked-changed
 *
 * @attribute data-narrow - a narrower version of the button
 * @slot [label] - optional. Replaces the label binding with an custom element.
 */
@autoinject()
export class CustomRadiobutton {
  @bindable public label = '';

  /**
   * name of the radio button group
   * radio buttons with the same name will uncheck each other
   */
  @bindable public name: string | null = null;

  @bindable public value = false;

  @bindable public model = true;

  @bindable public disabled = false;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleRadiobuttonChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'checked-changed',
      bubbles: true,
      detail: {
        checked: this.value
      }
    });
  }
}

export type CustomRadioButtonCheckedChanged = NamedCustomEvent<
  'checked-changed',
  { checked: any }
>;
