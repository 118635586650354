import { Settings } from 'common/Settings/Settings';

import { UrlManager } from './UrlManager';

export class HttpApi {
  public static async getAvailableServers(emailHash: string): Promise<{
    availableServers: Settings['ServerEndpoints'];
    userExistsOnServer: boolean;
  }> {
    const response = await $.post(`${UrlManager.webFolder}/api/servers`, {
      emailHash
    });

    if ('success' in response && response.success) {
      return {
        availableServers: response.availableServers,
        userExistsOnServer: response.userExistsOnServer
      };
    } else {
      throw new Error('Could not retrieve available servers');
    }
  }
}
