import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

@autoinject()
export class LockedBySignedReportProjectIdsComputer extends ValueComputer<
  Record<string, never>,
  LockedBySignedReportProjectIdsComputerValue
> {
  private readonly subscriptionManager: SubscriptionManager;

  private disableExportOfProjectsWithSignedReports = true;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToMultipleModelChanges(
      [EntityName.Project, EntityName.Report],
      () => {
        invokeCompute();
      }
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'pdfSigning.disableExportOfProjectsWithSignedReports',
        (disableExportOfProjectsWithSignedReports) => {
          this.disableExportOfProjectsWithSignedReports =
            disableExportOfProjectsWithSignedReports;
          invokeCompute();
        }
      )
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): LockedBySignedReportProjectIdsComputerValue {
    const value: LockedBySignedReportProjectIdsComputerValue = {
      lockedExportProjectIds: new Set()
    };
    if (this.disableExportOfProjectsWithSignedReports) {
      const projects = this.entityManager.projectRepository.getAll();
      for (const project of projects) {
        const reports = this.entityManager.reportRepository.getByProjectId(
          project.id
        );
        if (reports.some((r) => r.signatureTime != null)) {
          value.lockedExportProjectIds.add(project.id);
        }
      }
    }
    return value;
  }
}

export type LockedBySignedReportProjectAttributes = {
  allowExport: boolean;
};

export type LockedBySignedReportProjectIdsComputerValue = {
  lockedExportProjectIds: Set<string>;
};
