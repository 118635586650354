import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { Settings } from 'common/Settings/Settings';

import { Utils } from '../../../classes/Utils/Utils';
import { UrlManager } from '../../../classes/UrlManager';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { environment } from '../../../environment';
import { DeviceInfoHelper } from '../../../classes/DeviceInfoHelper';
import { NotificationHelper } from '../../../classes/NotificationHelper';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import { HttpApi } from '../../../classes/HttpApi';

@autoinject()
export class LoginForm {
  private domElement: HTMLElement;

  @bindable public email = '';
  @bindable public password = '';

  @bindable public loading = false;

  protected showPassword = false;

  protected selectedServer: Settings['ServerEndpoints'][number] | null = null;
  protected availableServers: Settings['ServerEndpoints'] = [];

  protected isLoadingAvailableServers = false;

  private handleEmailValueChangedDebounced = Utils.debounceFunction(() => {
    void this.handleEmailValueChanged();
  }, 300);

  constructor(
    element: Element,
    private readonly i18n: I18N
  ) {
    this.domElement = element as HTMLElement;
  }

  protected async attached(): Promise<void> {
    const selectedServer = UrlManager.selectedServer;

    if (selectedServer) {
      this.selectedServer = selectedServer;
    }
  }

  protected async emailChanged(): Promise<void> {
    await this.handleEmailValueChangedDebounced();
  }

  @computedFrom()
  protected get signUpEnabled(): boolean {
    return !environment.disableSignUp;
  }

  @computed(expression('selectedServer'), expression('availableServers.length'))
  protected get showServerSelect(): boolean {
    return !this.selectedServer && !!this.availableServers.length;
  }

  protected async handleSelectServer(
    server: Settings['ServerEndpoints'][number]
  ): Promise<void> {
    this.selectedServer = server;

    if (DeviceInfoHelper.isApp()) {
      await UrlManager.setSelectedServer(this.selectedServer);
    } else {
      Utils.redirectToNewLocation(
        this.selectedServer.webUrl + `/#?email=${this.email}`
      );
    }
  }

  protected async handleDeselectServer(): Promise<void> {
    await UrlManager.setSelectedServer(null);

    this.selectedServer = null;

    await this.updateAvailableServers();
  }

  protected handleToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword;
  }

  protected handleLoginFormSubmit(): void {
    DomEventHelper.fireEvent<LoginClicked>(this.domElement, {
      name: 'login-clicked',
      detail: null
    });
  }

  protected handleResetPasswordClicked(): void {
    DomEventHelper.fireEvent<ResetPasswordClicked>(this.domElement, {
      name: 'reset-password-clicked',
      detail: null
    });
  }

  protected handleRegisterClicked(): void {
    DomEventHelper.fireEvent<RegisterClicked>(this.domElement, {
      name: 'register-clicked',
      detail: null
    });
  }

  private async handleEmailValueChanged(): Promise<void> {
    if (this.email.length < 3) return;

    await this.updateAvailableServers({ autoSelectFirstServer: true });
  }

  private async updateAvailableServers({
    autoSelectFirstServer = false
  }: {
    autoSelectFirstServer?: boolean;
  } = {}): Promise<void> {
    this.isLoadingAvailableServers = true;

    try {
      const { availableServers, userExistsOnServer } =
        await this.getAvailableServers(this.email);

      this.availableServers = availableServers;

      const firstAvailableServer = availableServers[0];
      if (
        autoSelectFirstServer &&
        DeviceInfoHelper.isApp() &&
        !userExistsOnServer &&
        firstAvailableServer
      ) {
        await this.handleSelectServer(firstAvailableServer);
      }
    } catch (e) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          `generalPages.auth.loginForm.errorWhileTryingToGetAvailableServers`
        )
      );

      this.availableServers = [];
    } finally {
      this.isLoadingAvailableServers = false;
    }
  }

  private async getAvailableServers(email: string): Promise<{
    availableServers: Settings['ServerEndpoints'];
    userExistsOnServer: boolean;
  }> {
    const emailHash = await window.crypto.subtle
      .digest('SHA-256', new TextEncoder().encode(email))
      .then((hashBuffer) =>
        Array.from(new Uint8Array(hashBuffer))
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('')
      );

    return await HttpApi.getAvailableServers(emailHash);
  }
}

export type LoginClicked = NamedCustomEvent<'login-clicked'>;

export type ResetPasswordClicked = NamedCustomEvent<'reset-password-clicked'>;

export type RegisterClicked = NamedCustomEvent<'register-clicked'>;
