import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EditThingDialog } from '../../dialogs/edit-thing-dialog/edit-thing-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

/**
 * @event thing-ids-changed
 */
@autoinject()
export class MultiThingSelectAndEditWidget {
  /**
   * this is read-only atm (but can be changed to make it actually update from outside changes)
   */
  @bindable()
  public thingIds: Array<string> = [];

  /**
   * has to be set for the create thing feature to work
   */
  @bindable()
  public userGroupId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * only show things belonging to the given thingGroup
   */
  @bindable()
  public thingGroup: ThingGroup | null = null;

  @bindable()
  public personsEnabled: boolean = false;

  @bindable()
  public title: string = '';

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private items: Array<Item> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public ensureAtLeastOneThing(): void {
    if (this.items.length === 0) {
      this.items.push({
        thingId: null
      });
    }
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Thing,
        this.updateLocalThingIds.bind(this)
      )
    );

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      '_items',
      this.handleThingSelectValueChanged.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleAddThingClick(): void {
    this.items.push({
      thingId: null
    });
  }

  protected handleRemoveThingClick(thingId: string): void {
    const index = this.items.findIndex((i) => i.thingId === thingId);
    if (index >= 0) this.items.splice(index, 1);
  }

  protected handleThingSelectValueChanged(): void {
    const ids = new Set<string>();

    this.items.forEach((i) => {
      if (i.thingId) {
        ids.add(i.thingId);
      }
    });

    this.setThingIds(Array.from(ids));
  }

  protected handleEditThingClick(thingId: string): void {
    const thing = this.entityManager.thingRepository.getById(thingId);
    if (!thing) return;

    void EditThingDialog.open({
      thing: thing
    });
  }

  private updateLocalThingIds(): void {
    let changed = false;

    this.items.forEach((item) => {
      const thing = item.thingId
        ? this.entityManager.thingRepository.getByOriginalId(item.thingId)
        : null;
      if (thing) {
        item.thingId = thing.id;
        changed = true;
      }
    });

    if (changed) {
      this.fireThingIdsChangedEvent();
    }
  }

  private setThingIds(thingIds: Array<string>): void {
    this.thingIds = thingIds;
    this.fireThingIdsChangedEvent();
  }

  private fireThingIdsChangedEvent(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent(this.element, {
        name: 'thing-ids-changed',
        detail: null
      });
    }, 0);
  }
}

type Item = {
  thingId: string | null;
  thing?: Thing | null;
};
