import { EntityInfo } from '@record-it-npm/synchro-common';

import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';

import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { entryEntityInfo } from '../../../../classes/EntityManager/entities/Entry/entryEntityInfo';
import { Entry } from '../../../../classes/EntityManager/entities/Entry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { LockedByFinishedAppointmentsProjectIdsComputer } from '../../../../computedValues/computers/LockedByFinishedAppointmentsProjectIdsComputer/LockedByFinishedAppointmentsProjectIdsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';

export class EntryAdapter implements EntityAdapter<Entry> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private projectIdsWhereUserIsAuthorized: Set<string> = new Set();
  private lockedByFinishedAppointmentsProjectIdsSet: Set<string> = new Set();

  constructor(options: EntryAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ projectIdsWhereUserIsAuthorized }) => {
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass: LockedByFinishedAppointmentsProjectIdsComputer,
        computeData: {},
        callback: (lockedByFinishedAppointmentsProjectIdsSet) => {
          this.lockedByFinishedAppointmentsProjectIdsSet =
            lockedByFinishedAppointmentsProjectIdsSet;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanDeleteEntries()
    });
  }

  public canEditField(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canEditPictures(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canEditProperties(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canCreateEntryToPersons(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canCreateTextBricks(entry: Entry): boolean {
    return this.checkEntryPermissions({
      entry,
      checkRolePermission: (permissions) => permissions.getCanCreateTextBricks()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Entry,
    Entry
  > {
    return entryEntityInfo;
  }

  private checkEntryPermissions({
    entry,
    checkRolePermission
  }: {
    entry: Entry;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (
      this.lockedByFinishedAppointmentsProjectIdsSet.has(entry.ownerProjectId)
    ) {
      return false;
    }

    return EntityAdapterUtils.checkProjectSubEntityPermission({
      entity: entry,
      projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type EntryAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
