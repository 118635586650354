import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskCommentEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskComment/processTaskCommentEntityInfo';
import { ProcessTaskComment } from '../../../../classes/EntityManager/entities/ProcessTaskComment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskCommentAdapter
  implements EntityAdapter<ProcessTaskComment>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized = new Set<string>();

  constructor(options: ProcessTaskCommentAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(processTaskComment: ProcessTaskComment): boolean {
    return this.checkPermission({
      processTaskComment,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskComments()
    });
  }

  public canEditField(processTaskComment: ProcessTaskComment): boolean {
    return this.checkPermission({
      processTaskComment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskComments()
    });
  }

  public canEditGeneralFiles(processTaskComment: ProcessTaskComment): boolean {
    return this.checkPermission({
      processTaskComment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskComments()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskComment,
    ProcessTaskComment
  > {
    return processTaskCommentEntityInfo;
  }

  private checkPermission({
    processTaskComment,
    checkRolePermission
  }: {
    processTaskComment: ProcessTaskComment;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskComment,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskCommentAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
