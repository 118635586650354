import { autoinject } from 'aurelia-framework';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class PictureValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    canTakePictures,
    configurationStep,
    processTask,
    appointment
  }: FinishAppointmentOptionsWithConfigurationStep): boolean {
    const needsPictures =
      (configurationStep?.picturesRequired ?? true) && canTakePictures;

    if (!needsPictures) {
      return true;
    }

    return this.atLeastOnePicture({ processTask, appointment });
  }

  private atLeastOnePicture({
    processTask,
    appointment
  }: {
    processTask: ProcessTask;
    appointment: ProcessTaskAppointment;
  }): boolean {
    const processTaskToProject =
      this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndAppointmentId(
        processTask.id,
        appointment.id,
        ProcessTaskToProjectType.GENERAL
      )[0];
    if (!processTaskToProject) {
      return false;
    }

    const entries = this.entityManager.entryRepository.getByParentId(
      processTaskToProject.projectId,
      null,
      null
    );
    return entries.length > 0;
  }
}
