import { autoinject, bindable } from 'aurelia-framework';

import { ProcessConfigurationCategoryHelper } from 'common/EntityHelper/ProcessConfigurationCategoryHelper';
import { OperationsExpressionEditorScope } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsExpressionEditorScope';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';
import { ExpressionEditorScopeEvaluationUtils } from 'common/ExpressionEditorScope/ExpressionEditorScopeEvaluationUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskAppointmentsWithWarningsDialog } from '../process-task-appointments-with-warnings-dialog/process-task-appointments-with-warnings-dialog';
import { AppointmentCountdownUtils } from './AppointmentCountdownUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskUtils } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { ProcessTaskAppointmentUtils } from '../../classes/EntityManager/entities/ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessConfigurationStepBarDimension } from '../process-configuration-step-bar/ProcessConfigurationStepBarDimensionCalculator';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import {
  ProcessTaskOfferToProcessTaskPositionByPositionId,
  ProcessTaskOfferToProcessTaskPositionMapComputer
} from '../../computedValues/computers/ProcessTaskOfferToProcessTaskPositionMapComputer/ProcessTaskOfferToProcessTaskPositionMapComputer';
import { Utils } from '../../classes/Utils/Utils';
import { HighlightAnimator } from '../../classes/Animation/HighlightAnimator';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { OperationsDataFetcher } from '../../classes/Operations/OperationsDataFetcher';

@autoinject()
export class ProcessTaskGroupsTableViewProcessTask {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public stepBarDimension: ProcessConfigurationStepBarDimension | null = null;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStep/types').ProcessConfigurationStep>} */
  @bindable()
  public processConfigurationSteps: Array<ProcessConfigurationStep> = [];

  protected title = '';

  private readonly domElement: HTMLElement;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly processTaskSubscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  private processTaskAppointmentInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();

  protected processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  private activeProcessTaskOfferToProcessTaskPositionByPositionId: ProcessTaskOfferToProcessTaskPositionByPositionId =
    new Map();

  private appointments: Array<ProcessTaskAppointment> = [];
  private appointmentWarningVisible: boolean = false;
  private processConfigurationStep: ProcessConfigurationStep | null = null;

  /**
   * days until the first appointment in the past which hasn't been finished
   * if all appointments in the past are done it will be the days until the next appointment
   * null if there is relevant appointment
   */
  private countdownToAppointment: number | null = null;
  protected offerInfos: Array<{ abbreviation: string; count: number }> = [];
  protected positionInfos: Array<{
    abbreviation: string;
    count: number;
    inOfferCount: number;
  }> = [];

  private actionStatusText: string | null = null;

  /**
   * Complicated workaround to detect if the component was rendered completely.
   * It seems like, the processTaskName is the last element to be rendered -> this can change in the future if e.g. the layout changed.
   *
   * Resolving the promiseContainer as soon, as the title is requested (via getProcessTaskName) isn't enough, because it won't get rendered yet.
   * That's why we have a workaround with an invisible span element so we can get notified that aurelia actually rendered it.
   * After this element has been rendered, everything should be pretty surely rendered. At least until the current processTask.
   * Others further down the list could still be not rendered, but they are not relevant to calculate the scrolling position.
   */
  private processTaskNameRenderedPromiseContainer =
    new PromiseContainer<void>();

  /**
   * Is true if a non fallback processTaskName was returned from getProcessTaskName
   */
  protected startedRenderingProcessTaskName: boolean = false;

  private readonly operationsExpressionEditor: OperationsExpressionEditorScope<
    string,
    string
  >;

  private readonly exprParser: ExprEvalParser;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.processTaskSubscriptionManager = subscriptionManagerService.create();
    this.operationsExpressionEditor = new OperationsExpressionEditorScope(
      new OperationsDataFetcher(entityManager)
    );
    this.exprParser = new ExprEvalParser();
  }

  public scrollToElement(): void {
    void this.processTaskNameRenderedPromiseContainer.create().then(() => {
      void ScrollHelper.scrollToItemCentered(this.domElement);

      const animator = new HighlightAnimator(this.domElement);
      animator.highlightBackground();
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointments.bind(this)
    );
    this.updateAppointments();

    const updateTitle = (): void => {
      void this.updateTitle();
    };
    updateTitle();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      updateTitle
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateTitle
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      updateTitle
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateTitle
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOffer,
      this.updateOfferInfos.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateOfferInfos.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateOfferInfos.bind(this)
    );
    this.updateOfferInfos();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskOfferToProcessTaskPositionMapComputer,
        callback: ({
          activeProcessTaskOfferToProcessTaskPositionByPositionId
        }) => {
          this.activeProcessTaskOfferToProcessTaskPositionByPositionId =
            activeProcessTaskOfferToProcessTaskPositionByPositionId;
          this.updatePositionInfos();
        },
        computeData: {}
      })
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositionInfos.bind(this)
    );
    this.updatePositionInfos();

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTask.currentProcessConfigurationStepId',
      this.updateProcessConfigurationStep.bind(this)
    );
    this.updateProcessConfigurationStep();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentInfoMap) => {
          this.processTaskAppointmentInfoMap = processTaskAppointmentInfoMap;
          this.updateAppointmentWarningVisible();
          this.updateCountdown();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
          void this.updateTitle();
        }
      })
    );

    this.updateProcessTaskSubscriptions();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    this.processTaskSubscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskSubscriptions();
      this.updateAppointments();
      this.updateOfferInfos();
      this.updatePositionInfos();
      void this.updateTitle();
    }
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      void this.updateTitle();
    }
  }

  private updateAppointments(): void {
    this.appointments = this.processTask
      ? this.entityManager.processTaskAppointmentRepository.getByProcessTaskId(
          this.processTask.id
        )
      : [];
    this.updateCountdown();
    this.updateAppointmentWarningVisible();
  }

  private updateCountdown(): void {
    this.countdownToAppointment = AppointmentCountdownUtils.getCountdown(
      this.appointments,
      this.processTaskAppointmentInfoMap
    );
  }

  private updateProcessTaskSubscriptions(): void {
    this.processTaskSubscriptionManager.disposeSubscriptions();

    if (this.processTask) {
      const propertyNames = [
        'processConfigurationActionStatusId',
        'customActionStatusName',
        'customActionStatusAbbreviation'
      ];
      this.processTaskSubscriptionManager.subscribeToMultiplePropertyChanges(
        this.processTask,
        propertyNames,
        this.updateActionStatusText.bind(this)
      );
    }

    this.updateActionStatusText();
  }

  private updateActionStatusText(): void {
    let displayName = '';

    if (this.processTask) {
      displayName = ProcessTaskUtils.getActionStatusDisplayName(
        this.processTask.processConfigurationActionStatusId,
        this.processTask.customActionStatusName,
        this.processTask.customActionStatusAbbreviation,
        this.entityManager
      );
    }

    this.actionStatusText = displayName ? displayName : null;
  }

  private updateAppointmentWarningVisible(): void {
    this.appointmentWarningVisible = this.appointments.some((appointment) => {
      const info = this.processTaskAppointmentInfoMap.get(appointment.id);
      if (!info) {
        return false;
      }

      return ProcessTaskAppointmentUtils.isInPastAndNotFinished(
        appointment.finishedAt,
        info.dateTo
      );
    });
  }

  private updateOfferInfos(): void {
    if (this.processTask) {
      const offerRelations =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
          this.processTask.id
        );
      const offerIds = offerRelations.map((r) => r.processTaskOfferId);
      const allOffers =
        this.entityManager.processTaskOfferRepository.getByIds(offerIds);
      const offersByCategoryId = Utils.groupBy(
        allOffers,
        (offer) => offer.processConfigurationCategoryId
      );

      this.offerInfos = Array.from(offersByCategoryId.entries()).map(
        ([categoryId, offers]) => {
          const category = categoryId
            ? this.entityManager.processConfigurationCategoryRepository.getById(
                categoryId
              )
            : null;
          const abbreviation = category
            ? ProcessConfigurationCategoryHelper.getAbbreviationWithFallback(
                category.abbreviation,
                category.name
              )
            : null;
          return {
            abbreviation: abbreviation ?? '*',
            count: offers.length
          };
        }
      );
    } else {
      this.offerInfos = [];
    }
  }

  private updatePositionInfos(): void {
    if (this.processTask) {
      const allPositions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        );
      const positionsByCategoryId = Utils.groupBy(
        allPositions,
        (position) => position.processConfigurationCategoryId
      );

      this.positionInfos = Array.from(positionsByCategoryId.entries())
        .map(([categoryId, positions]) => {
          const category = categoryId
            ? this.entityManager.processConfigurationCategoryRepository.getById(
                categoryId
              )
            : null;
          const abbreviation = category
            ? ProcessConfigurationCategoryHelper.getAbbreviationWithFallback(
                category.abbreviation,
                category.name
              )
            : null;
          const positionsInOffer = positions.filter((position) => {
            const relations =
              this.activeProcessTaskOfferToProcessTaskPositionByPositionId.get(
                position.id
              ) ?? [];
            return !!relations.length;
          });

          return {
            abbreviation: abbreviation ?? '*',
            count: positions.length,
            inOfferCount: positionsInOffer.length
          };
        })
        .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    } else {
      this.positionInfos = [];
    }
  }

  private updateProcessConfigurationStep(): void {
    const stepId = this.processTask?.currentProcessConfigurationStepId ?? null;
    this.processConfigurationStep =
      this.processConfigurationSteps.find((s) => s.id === stepId) ?? null;
  }

  private async updateTitle(): Promise<void> {
    assertNotNullOrUndefined(
      this.processTask,
      'cannot get text without processTask'
    );
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot get text without processTaskGroup'
    );
    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        this.processTaskGroup.processConfigurationId
      );
    const titleConfigExpr =
      processConfiguration?.configurableDisplayText
        ?.processTaskGroupOverviewProcessTaskTitle;
    if (titleConfigExpr) {
      this.title = await this.evaluateConfig(titleConfigExpr);
      return;
    }

    this.title = this.getProcessTaskName(
      this.processTask.id,
      this.processTaskNamesByProcessTaskId
    );
  }

  private getProcessConfigurationStep(
    processConfigurationSteps: Array<ProcessConfigurationStep>,
    stepId: string
  ): ProcessConfigurationStep | null {
    return processConfigurationSteps.find((s) => s.id === stepId) ?? null;
  }

  private getStepIndicatorOffset(
    stepBarDimension: ProcessConfigurationStepBarDimension | null,
    stepId: string
  ): number {
    if (stepBarDimension) {
      for (const group of stepBarDimension.groupDimensions) {
        for (const step of group.stepDimensions) {
          if (step.stepId === stepId) {
            return step.centerOffsetLeft;
          }
        }
      }
    }

    return -1000;
  }

  private getStepIconCustomText(
    countdownToNextAppointment: number | null,
    actionStatusText: string | null
  ): string | null {
    if (countdownToNextAppointment != null) {
      return countdownToNextAppointment.toString();
    }

    if (actionStatusText != null) {
      return actionStatusText;
    }

    return null;
  }

  private handleWarningIconClick(): void {
    if (!this.processTask) {
      throw new Error(
        "no process task is set, can't open ProcessTaskAppointmentsWithWarningsDialog"
      );
    }

    void ProcessTaskAppointmentsWithWarningsDialog.open({
      processTask: this.processTask
    });
  }

  protected getProcessTaskName(
    processTaskId: string,
    processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId
  ): string {
    const names = processTaskNamesByProcessTaskId.get(processTaskId);

    // this check is necessary to ensure we have the correct computed value and not just the initial value
    if (names) {
      this.startedRenderingProcessTaskName = true;
    }

    return names?.nameWithThingAndPerson ?? '';
  }

  private async evaluateConfig(expr: string): Promise<string> {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot get text without processTaskGroup'
    );
    assertNotNullOrUndefined(
      this.processTask,
      'cannot get text without processTask'
    );

    const fieldConfigs =
      await this.operationsExpressionEditor.createFieldConfigsForProcessTaskScope(
        {
          currentProcessTaskGroup: {
            id: this.processTaskGroup.id
          },
          currentThingGroup: {
            id: this.processTaskGroup.thingGroupId
          },
          currentProcessTask: {
            id: this.processTask.id
          }
        }
      );
    const dataToSet =
      await ExpressionEditorScopeEvaluationUtils.convertToPlainObject(
        fieldConfigs
      );
    return this.exprParser.evaluateExpression(expr, dataToSet).toString();
  }
}
