import { autoinject } from 'aurelia-framework';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { Utils } from 'common/Utils';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ComputedValueService } from '../../ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ThingAuthorizationComputer extends ValueComputer<
  Record<never, never>,
  ThingAuthorizationComputerResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(invokeCompute)
    );

    this.subscriptionManager.subscribeToMultipleModelChanges(
      [
        EntityName.ThingAuthorization,
        EntityName.ProcessTask,
        EntityName.Thing,
        EntityName.Project
      ],
      invokeCompute
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        callback: invokeCompute,
        computeData: {},
        skipInitialCall: true
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        callback: invokeCompute,
        computeData: {},
        skipInitialCall: true
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ThingAuthorizationComputerResult {
    const currentUser = this.currentUserService.getCurrentUser();

    const roleBasedPermissions = this.computedValueService.getCurrentValue(
      RoleBasedPermissionsComputer,
      {}
    );

    const result = this.computedValueService.getCurrentValue(
      ProcessTaskGroupAuthorizationComputer,
      {}
    );

    const thingIdsWhereUserIsAuthorized =
      currentUser && roleBasedPermissions && result
        ? this.getThingIdsWhereUserIsAuthorized({
            currentUser,
            roleBasedPermissions,
            processTaskIdsWhereUserIsAuthorized:
              result.processTaskIdsWhereUserIsAuthorized
          })
        : new Set<string>();

    return {
      thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing: roleBasedPermissions
        ? this.getThingGroupIdsWhereUserIsAuthorized({
            thingIdsWhereUserIsAuthorized,
            roleBasedPermissions
          })
        : new Set(),
      thingIdsWhereUserIsAuthorized,
      projectIdsWhereUserIsAuthorized: this.getProjectIdsWhereUserIsAuthorized({
        thingIdsWhereUserIsAuthorized
      })
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getThingIdsWhereUserIsAuthorized({
    currentUser,
    roleBasedPermissions,
    processTaskIdsWhereUserIsAuthorized
  }: {
    currentUser: User;
    roleBasedPermissions: RoleBasedPermissions;
    processTaskIdsWhereUserIsAuthorized: Set<string>;
  }): Set<string> {
    const thingIdToHasThingAuthorization =
      this.getThingIdToHasThingAuthorization({ currentUser });

    const thingIdToProcessTasks = Utils.groupValues(
      this.entityManager.processTaskRepository.getAll(),
      (processTask) => processTask.thingId
    );

    return new Set(
      this.entityManager.thingRepository
        .getAll()
        .filter((thing) => {
          const userGroupSpecificPermissions =
            roleBasedPermissions.inUserGroupId(thing.ownerUserGroupId);
          if (
            !userGroupSpecificPermissions.getControlEntityVisibilityWithAuthorizations() ||
            userGroupSpecificPermissions.getCanSeeEntitiesWithoutThingAuthorization()
          ) {
            return true;
          }

          if (thingIdToHasThingAuthorization.get(thing.id)) {
            return true;
          }

          const processTasks = thingIdToProcessTasks.get(thing.id) ?? [];

          for (const processTask of processTasks) {
            if (processTaskIdsWhereUserIsAuthorized.has(processTask.id)) {
              return true;
            }
          }

          return false;
        })
        .map((thing) => thing.id)
    );
  }

  private getThingGroupIdsWhereUserIsAuthorized({
    thingIdsWhereUserIsAuthorized,
    roleBasedPermissions
  }: {
    thingIdsWhereUserIsAuthorized: Set<string>;
    roleBasedPermissions: RoleBasedPermissions;
  }): Set<string> {
    const thingGroupIdToThings = Utils.groupValues(
      this.entityManager.thingRepository.getAll(),
      (thing) => thing.thingGroupId
    );
    return new Set(
      this.entityManager.thingGroupRepository
        .getAll()
        .filter((thingGroup) => {
          const userGroupSpecificPermissions =
            roleBasedPermissions.inUserGroupId(thingGroup.ownerUserGroupId);
          if (
            !userGroupSpecificPermissions.getControlEntityVisibilityWithAuthorizations() ||
            userGroupSpecificPermissions.getCanSeeEntitiesWithoutThingAuthorization()
          ) {
            return true;
          }

          const things = thingGroupIdToThings.get(thingGroup.id) ?? [];

          return things.some((thing) =>
            thingIdsWhereUserIsAuthorized.has(thing.id)
          );
        })
        .map((thingGroup) => thingGroup.id)
    );
  }

  private getProjectIdsWhereUserIsAuthorized({
    thingIdsWhereUserIsAuthorized
  }: {
    thingIdsWhereUserIsAuthorized: Set<string>;
  }): Set<string> {
    return new Set(
      this.entityManager.projectRepository
        .getAll()
        .filter((project) => {
          return thingIdsWhereUserIsAuthorized.has(project.thing);
        })
        .map((project) => project.id)
    );
  }

  private getThingIdToHasThingAuthorization({
    currentUser
  }: {
    currentUser: User;
  }): Map<string, boolean> {
    const thingIdToHasThingAuthorization = new Map<string, boolean>();

    for (const thingAuthorization of this.entityManager.thingAuthorizationRepository.getAll()) {
      if (thingAuthorization.userId !== currentUser.id) {
        continue;
      }

      thingIdToHasThingAuthorization.set(thingAuthorization.thingId, true);
    }

    return thingIdToHasThingAuthorization;
  }
}

export type ThingAuthorizationComputerResult = {
  thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing: Set<string>;
  thingIdsWhereUserIsAuthorized: Set<string>;
  projectIdsWhereUserIsAuthorized: Set<string>;
};
