import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { Picture } from '../Picture/types';
import { EntityName } from '../types';
import { PictureRevision, PictureRevisionCreationEntity } from './types';

export class PictureRevisionRepository extends AppEntityRepository<EntityName.PictureRevision> {
  public createPictureRevisionForPicture(picture: Picture): PictureRevision {
    return this.create({
      pictureId: picture.id,
      ownerThingId: picture.ownerThingId,
      ownerDefectId: picture.ownerDefectId,
      ownerProjectId: picture.ownerProjectId,
      ownerProcessTaskGroupId: picture.ownerProcessTaskGroupId,
      ownerUserGroupId: picture.ownerUserGroupId,
      sequenceNumber: null,
      selected: true
    } as PictureRevisionCreationEntity);
  }

  public getByPictureId(pictureId: string): Array<PictureRevision> {
    return this.getAll().filter((entity) => {
      return entity.pictureId === pictureId;
    });
  }

  public getActiveRevisionByPictureId(
    pictureId: string
  ): PictureRevision | null {
    return (
      this.getAll()
        .filter((entity) => {
          return entity.pictureId === pictureId;
        })
        .find((entity) => entity.selected) ?? null
    );
  }

  public setFallbackRevisionForPicture(pictureId: string): void {
    if (this.getActiveRevisionByPictureId(pictureId)) return;

    const latestRevision = this.getLatestRevisionForPicture(pictureId);

    if (!latestRevision) return;

    latestRevision.selected = true;
    this.update(latestRevision);
  }

  public getLatestRevisionForPicture(
    pictureId: string
  ): PictureRevision | null {
    const revisions = this.getAll().filter((entity) => {
      return entity.pictureId === pictureId;
    });

    const sortedRevisions = BaseEntityUtils.sortByCreationOrder(revisions);
    return sortedRevisions[sortedRevisions.length - 1] ?? null;
  }
}
