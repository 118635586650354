import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTask/processTaskEntityInfo';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskAdapter implements EntityAdapter<ProcessTask> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized = new Set<string>();
  private canUploadPicturesSetting: boolean = true;

  constructor(options: ProcessTaskAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.canUploadPictures',
        (canUploadPicturesSetting) => {
          this.canUploadPicturesSetting = canUploadPicturesSetting ?? true;
          updateBindings();
        }
      )
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTasks()
    });
  }

  public canEditField(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTasks()
    });
  }

  public canEditProperties(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTasks()
    });
  }

  public canUploadPictures(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTasks() && this.canUploadPicturesSetting
    });
  }

  public canCreateProcessTaskComments(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskComments()
    });
  }

  public canCreateProcessTaskChecklistEntries(
    processTask: ProcessTask
  ): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskChecklistEntries()
    });
  }

  public canCreateProcessTaskAppointments(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskAppointments()
    });
  }

  public canCreateProcessTaskRecurringAppointments(
    processTask: ProcessTask
  ): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskRecurringAppointments()
    });
  }

  public canCreateProcessTaskDevices(processTask: ProcessTask): boolean {
    return this.checkPermission({
      processTask,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskDevices()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTask,
    ProcessTask
  > {
    return processTaskEntityInfo;
  }

  private checkPermission({
    processTask,
    checkRolePermission
  }: {
    processTask: ProcessTask;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkPermissionBasedOnProcessTaskId({
      processTaskId: processTask.id,
      ownerUserGroupId: processTask.ownerUserGroupId,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
};
