import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { textBrickEntityInfo } from '../../../../classes/EntityManager/entities/TextBrick/textBrickEntityInfo';
import { TextBrick } from '../../../../classes/EntityManager/entities/TextBrick/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class TextBrickAdapter implements EntityAdapter<TextBrick> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private projectIdsWhereUserIsAuthorized: Set<string> = new Set();

  constructor(options: TextBrickAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ projectIdsWhereUserIsAuthorized }) => {
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(textBrick: TextBrick): boolean {
    return this.checkPermission({
      textBrick,
      checkRolePermission: (permissions) => permissions.getCanUpdateTextBricks()
    });
  }

  public canDeleteEntity(textBrick: TextBrick): boolean {
    return this.checkPermission({
      textBrick,
      checkRolePermission: (permissions) => permissions.getCanDeleteTextBricks()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.TextBrick,
    TextBrick
  > {
    return textBrickEntityInfo;
  }

  private checkPermission({
    textBrick,
    checkRolePermission
  }: {
    textBrick: TextBrick;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return EntityAdapterUtils.checkProjectSubEntityPermission({
      entity: textBrick,
      projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type TextBrickAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
