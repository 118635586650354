import { DateUtils } from 'common/DateUtils';
import { Thing } from '../Thing/types';
import { User } from '../User/types';

export class ProjectUtils {
  private constructor() {}

  public static getKfvPukProjectName({
    currentUser,
    thing
  }: {
    currentUser: User | null;
    thing: Thing;
  }): string {
    const yearString = DateUtils.formatToLastTwoYearDigitsString(new Date());
    const alias = currentUser?.alias;

    return `${yearString}-IB-${thing.name}-${alias}`;
  }
}
