import { autoinject } from 'aurelia-framework';
import { MapUtils } from 'common/Utils/MapUtils/MapUtils';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';
import {
  PersonIdToThingIdsComputerUtils,
  PersonIdToThingIdsMap
} from './PersonIdToThingIdsComputerUtils';

@autoinject()
export class PersonIdToThingIdsComputer extends ValueComputer<
  Record<never, never>,
  PersonIdToThingIdsComputerValue
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToMultipleModelChanges(
      [EntityName.PropertyToPerson, EntityName.Thing, EntityName.Property],
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): PersonIdToThingIdsComputerValue {
    const things = this.entityManager.thingRepository.getAll();
    const thingGroupIdToThingIds = new Map<string | null, Array<string>>();

    for (const thing of things) {
      MapUtils.addIdsToIdMap({
        idsToAdd: [thing.id],
        key: thing.thingGroupId,
        idMap: thingGroupIdToThingIds
      });
    }

    return {
      personIdToThingIds:
        PersonIdToThingIdsComputerUtils.createPersonIdToThingIdsMap({
          propertyToPersons:
            this.entityManager.propertyToPersonRepository.getAll(),
          thingGroupIdToThingIds,
          getPropertyById: (id) =>
            this.entityManager.propertyRepository.getById(id)
        })
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type PersonIdToThingIdsComputerValue = {
  personIdToThingIds: PersonIdToThingIdsMap;
};
