import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { MomentInput } from 'moment';

import { DateUtils } from '../../../../common/src/DateUtils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { CoordinateHelper, Coordinates } from '../../classes/CoordinateHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProjectUtils } from '../../classes/EntityManager/entities/Project/ProjectUtils';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

/**
 * this is meant to be a single global instance
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProjectDialog {
  public static async open(options: TEditProjectDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private project: Project | null = null;

  private onDialogClosed: ((project: Project) => void) | null = null;

  private dialog: RecordItDialog | null = null;

  protected clientCoordinates: Coordinates | null = null;
  protected useKfvPukProjectNameGeneration: boolean | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: null
      });
  }

  public open(options: TEditProjectDialogOptions): void {
    this.project = options.project;

    this.projectPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: options.project
      });

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.useKfvPukProjectNameGeneration',
        (useKfvPukProjectNameGeneration) => {
          this.useKfvPukProjectNameGeneration = useKfvPukProjectNameGeneration;
        }
      )
    );

    this.onDialogClosed = options.onDialogClosed ?? null;

    this.clientCoordinates = CoordinateHelper.getClientCoordinates();

    if (this.dialog) this.dialog.open();
  }

  public close(): void {
    if (this.dialog) this.dialog.close();
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const project = this.project;

    this.project = null;
    this.clientCoordinates = null;
    this.onDialogClosed = null;
    this.subscriptionManager.disposeSubscriptions();

    if (project) {
      onDialogClosed?.(project);
    }
  }

  protected handleProjectChanged(): void {
    if (this.project) this.entityManager.projectRepository.update(this.project);
  }

  protected handleSetKfvPukProjectNameClick(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't EditProjectDialog.handleSetKfvPukProjectNameClick without project"
    );

    const name = ProjectUtils.getKfvPukProjectName({
      currentUser: this.currentUserService.getRequiredCurrentUser(),
      thing: this.entityManager.thingRepository.getRequiredById(
        this.project.thing
      )
    });

    if (this.project.name === name) {
      return;
    }

    this.project.name = name;
    this.entityManager.projectRepository.update(this.project);
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected canCalculateDistance(
    latitude: number | null,
    longitude: number | null,
    clientLatitude: number | null,
    clientLongitude: number | null
  ): boolean {
    return (
      this.isNumber(latitude) &&
      this.isNumber(longitude) &&
      this.isNumber(clientLatitude) &&
      this.isNumber(clientLongitude)
    );
  }

  protected calculateDistance(
    latitude: number,
    longitude: number,
    clientLatitude: number,
    clientLongitude: number
  ): string {
    return CoordinateHelper.calculateFormattedDistance(
      clientLongitude,
      clientLatitude,
      longitude,
      latitude
    );
  }

  private isNumber(i: any): boolean {
    return !isNaN(parseFloat(i));
  }
}

export interface TEditProjectDialogOptions {
  project: Project;
  onDialogClosed?: ((project: Project) => void) | null;
}
