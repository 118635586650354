import { autoinject, observable } from 'aurelia-framework';

import { AuthenticationService } from '../../services/AuthenticationService';

import { Utils } from '../../classes/Utils/Utils';
import { NotificationHelper } from '../../classes/NotificationHelper';

@autoinject()
export class reset_password {
  protected mode: Mode = Mode.ENTER_TOKEN;

  protected Mode = Mode;

  @observable protected password: string;

  @observable protected confirmationPassword: string;
  protected token = '';
  protected showPassword = false;

  protected loading = false;

  protected passwordInputErrorText = '';
  protected confirmationPasswordInputErrorText = '';

  protected passwordFieldsAreValid = false;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.setMode(Mode.ENTER_TOKEN);

    this.password = '';
    this.confirmationPassword = '';
  }

  protected activate(params: any): void {
    if (params.token) {
      this.token = params.token;
    }
  }

  protected attached(): void {
    if (this.token && this.mode !== Mode.ENTER_PASSWORD) {
      this.setMode(Mode.ENTER_PASSWORD);
    }
  }

  private setMode(mode: Mode): void {
    this.mode = mode;
    this.password = '';
    this.confirmationPassword = '';
    this.showPassword = false;
    this.loading = false;
  }

  protected handleToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword;
  }

  protected handleSetTokenFormSubmit(): void {
    if (this.token.trim() === '') {
      NotificationHelper.notifyDanger('Rücksetzcode muss ausgefüllt werden!');
    } else {
      this.setMode(Mode.ENTER_PASSWORD);
    }
  }

  protected async handleSetPasswordFormSubmit(): Promise<void> {
    if (
      this.password.trim() === '' ||
      this.confirmationPassword.trim() === ''
    ) {
      NotificationHelper.notifyDanger('Alle Felder müssen ausgefüllt sein!');
    } else if (this.password !== this.confirmationPassword) {
      NotificationHelper.notifyDanger(
        'Beide Passwörter müssen übereinstimmen!'
      );
    } else {
      try {
        this.loading = true;
        await this.authenticationService.resetPassword(
          this.token,
          this.password,
          this.confirmationPassword,
          { navigate: true }
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }

  protected passwordChanged(): void {
    this.handlePasswordFieldsChanged();
  }

  protected confirmationPasswordChanged(): void {
    this.handlePasswordFieldsChanged();
  }

  private handlePasswordFieldsChanged(): void {
    this.passwordFieldsAreValid = false;
    if (this.password || this.confirmationPassword) {
      if (!Utils.validatePassword(this.password)) {
        this.passwordInputErrorText =
          'Das Passwort muss aus mind. 8 Zeichen bestehen und mind. 1 Großbuchstaben enthalten!';
        return;
      } else {
        this.passwordInputErrorText = '';
      }
      if (
        this.confirmationPassword &&
        this.password !== this.confirmationPassword
      ) {
        this.confirmationPasswordInputErrorText =
          'Passwörter stimmen nicht überein!';
        return;
      } else {
        this.confirmationPasswordInputErrorText = '';
      }
      this.passwordFieldsAreValid = true;
    }
  }
}

enum Mode {
  'ENTER_TOKEN',
  'ENTER_PASSWORD'
}
