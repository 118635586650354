import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskChecklistEntryEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskChecklistEntry/processTaskChecklistEntryEntityInfo';
import { ProcessTaskChecklistEntry } from '../../../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskChecklistEntryAdapter
  implements EntityAdapter<ProcessTaskChecklistEntry>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized = new Set<string>();

  constructor(options: ProcessTaskChecklistEntryAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    return this.checkPermission({
      processTaskChecklistEntry,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskChecklistEntries()
    });
  }

  public canEditField(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    return this.checkPermission({
      processTaskChecklistEntry,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskChecklistEntries()
    });
  }

  public canEditGeneralFiles(
    processTaskChecklistEntry: ProcessTaskChecklistEntry
  ): boolean {
    return this.checkPermission({
      processTaskChecklistEntry,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskChecklistEntries()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskChecklistEntry,
    ProcessTaskChecklistEntry
  > {
    return processTaskChecklistEntryEntityInfo;
  }

  private checkPermission({
    processTaskChecklistEntry,
    checkRolePermission
  }: {
    processTaskChecklistEntry: ProcessTaskChecklistEntry;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskChecklistEntry,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskChecklistEntryAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
