import { autoinject, computedFrom } from 'aurelia-framework';

import { EntityGroupUtils } from 'common/EntityGrouper/EntityGroupUtils';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTaskDeviceGroupsForProcessTaskIdComputer } from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskDevicePropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProcessTaskDevicePropertyAdapter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { IconType } from '../../aureliaComponents/custom-icon/custom-icon';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import {
  NavigationButtonConfig,
  RecordItDialog
} from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessTaskDeviceDialog {
  public static async open(
    options: EditProcessTaskDeviceDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private readonly devicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskDevice];

  @subscribableLifecycle()
  private readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  protected dialog: RecordItDialog | null = null;

  protected device: ProcessTaskDevice | null = null;
  protected propertyAdapter: ProcessTaskDevicePropertyAdapter | null = null;
  protected processConfigurationDevice: ProcessConfigurationDevice | null =
    null;

  private onDialogClosed: (() => void) | null = null;
  private readOnly: boolean = false;
  private devices: Array<ProcessTaskDevice> = [];
  protected deviceIndex: number = -1;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.devicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskDevice,
        context: this,
        expression: 'device'
      });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForIdExpressionValue({
        entityName: EntityName.ProcessTask,
        context: this,
        expression: 'device.ownerProcessTaskId'
      });

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: EditProcessTaskDeviceDialogOpenOptions): void {
    this.device = options.processTaskDevice;
    this.onDialogClosed = options.onDialogClosed;

    this.readOnly = options.readOnly ?? false;
    this.devicePermissionsHandle.overrideAllPermissions(
      this.readOnly ? false : null
    );

    this.processConfigurationDevice =
      this.entityManager.processConfigurationDeviceRepository.getById(
        this.device.processConfigurationDeviceId
      );

    this.propertyAdapter = new ProcessTaskDevicePropertyAdapter({
      device: this.device,
      entityManager: this.entityManager,
      permissionsService: this.permissionsService,
      processTaskLoggingService: this.processTaskLoggingService,
      subscriptionManagerService: this.subscriptionManagerService
    });

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.devices = EntityGroupUtils.getAllEntities(groupedDevices);
          this.updateDeviceIndex();
        },
        createComputeData: () => {
          return this.device
            ? { processTaskId: this.device.ownerProcessTaskId }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'device.ownerProcessTaskId',
            updateSubscription
          );
        }
      })
    );

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.device = null;
    this.subscriptionManager.disposeSubscriptions();
    this.propertyAdapter = null;

    onClosed && onClosed();
  }

  protected handleDeviceChanged(property?: string): void {
    if (this.device) {
      this.entityManager.processTaskDeviceRepository.update(this.device);
      this.processTaskLoggingService.logProcessTaskSubEntityModified({
        entityName: EntityName.ProcessTaskDevice,
        entity: this.device,
        property: property ?? null,
        displayNameAtLogTime: null
      });
    }
  }

  private updateDeviceIndex(): void {
    this.deviceIndex = this.device ? this.devices.indexOf(this.device) : -1;
  }

  private copyDevice(device: ProcessTaskDevice): void {
    const copy = this.entityManager.processTaskDeviceRepository.create({
      discount: device.discount,
      discountNote: device.discountNote,
      plannedDuration: device.plannedDuration,
      dailyCost: device.dailyCost,
      processConfigurationDeviceId: device.processConfigurationDeviceId,
      ownerProcessTaskId: device.ownerProcessTaskId,
      ownerProcessTaskGroupId: device.ownerProcessTaskGroupId,
      ownerUserGroupId: device.ownerUserGroupId,
      temporaryGroupName: device.temporaryGroupName,
      shadowEntity: device.shadowEntity
    });

    this.copyDeviceProperties(device, copy);

    this.editDevice(copy);
  }

  private copyDeviceProperties(
    fromDevice: ProcessTaskDevice,
    toDevice: ProcessTaskDevice
  ): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskDeviceId(
        fromDevice.id
      );

    properties.forEach((property) => {
      this.entityManager.propertyRepository.create({
        name: property.name,
        choices: property.choices,
        custom_choice: property.custom_choice,
        type: property.type,
        value: property.value,
        alwaysVisible: property.alwaysVisible,
        active: property.active,
        order: property.order,
        options: property.options,
        hidden: property.hidden,
        processTaskDeviceId: toDevice.id,
        ownerUserGroupId: toDevice.ownerUserGroupId,
        ownerProcessTaskGroupId: toDevice.ownerProcessTaskGroupId,
        ownerProcessTaskId: toDevice.ownerProcessTaskId,
        temporaryGroupName: property.temporaryGroupName
      });
    });
  }

  private editDevice(device: ProcessTaskDevice): void {
    this.open({
      processTaskDevice: device,
      onDialogClosed: this.onDialogClosed,
      readOnly: this.readOnly
    });
  }

  @computedFrom(
    'device',
    'devices',
    'processTaskPermissionsHandle.canCreateProcessTaskDevices'
  )
  protected get navigationButtonConfigs(): Array<NavigationButtonConfig> {
    const configs: Array<NavigationButtonConfig> = [];

    const index = this.device ? this.devices.indexOf(this.device) : -1;
    const nextDevice = this.devices[index + 1];
    const previousDevice = this.devices[index - 1];

    if (nextDevice) {
      configs.push({
        name: 'nextDevice',
        iconName: 'fa-arrow-right',
        iconType: IconType.FAL,
        position: 'right',
        onClick: this.editDevice.bind(this, nextDevice)
      });
    }

    if (
      this.device &&
      this.processTaskPermissionsHandle.canCreateProcessTaskDevices
    ) {
      configs.push({
        name: 'copyDevice',
        iconName: 'fa-copy',
        iconType: IconType.FAL,
        position: 'right',
        onClick: this.copyDevice.bind(this, this.device)
      });
    }

    if (previousDevice) {
      configs.push({
        name: 'previousDevice',
        iconName: 'fa-arrow-left',
        iconType: IconType.FAL,
        position: 'left',
        onClick: this.editDevice.bind(this, previousDevice)
      });
    }

    return configs;
  }
}

export type EditProcessTaskDeviceDialogOpenOptions = {
  processTaskDevice: ProcessTaskDevice;
  readOnly?: boolean;
  onDialogClosed: (() => void) | null;
};
