export enum ExportType {
  DOCX = 'docx',
  PDF = 'pdf'
}

type OperationsExportCommonRequest = {
  exportType?: ExportType;
};

export type OperationsExportOfferRequest = OperationsExportCommonRequest & {
  processTaskOfferId: string;
};

export type OperationsExportOfferResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportInvoiceRequest = OperationsExportCommonRequest & {
  processTaskInvoiceId: string;
};

export type OperationsExportInvoiceResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportDevicesRequest = OperationsExportCommonRequest & {
  processTaskId: string;
  processConfigurationDeviceExportId: string;
};

export type OperationsExportDevicesResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportFormRequest = OperationsExportCommonRequest & {
  processTaskToProjectId: string;
};

export type OperationsExportFormResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportMeasurePointsRequest =
  OperationsExportCommonRequest & {
    processTaskId: string;
  };

export type OperationsExportMeasurePointsResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportPositionsAsCsvRequest = {
  processConfigurationId: string;
};

export type OperationsExportPositionsAsCsvResponse =
  OperationsExportEndpointsDefaultResponse;

export type OperationsExportEndpointsDefaultResponse =
  | OperationsExportEndpointsDefaultSuccessResponse
  | OperationsExportEndpointsDefaultErrorResponse;

export type OperationsExportEndpointsDefaultSuccessResponse = {
  success: true;
  token: string;
};

export type OperationsExportEndpointsDefaultErrorResponse = {
  success: false;
  status: string;
};
