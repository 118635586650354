import { PictureMainEntityInfo } from '../../EntityHelper/PictureHelper';
import { DateType, IdType } from '../../Types/Entities/Base/types';
import { PictureFileDto } from '../../Types/Entities/PictureFile/PictureFileDto';
import { ArrayUtils } from '../ArrayUtils';

export class PathUtils {
  public static getPathDetails(path: string): PathDetails {
    const { pathWithoutProtocol, protocol } = this.extractProtocol(path);

    // paths with leading slashes would lead to an empty string in front of the other pathElements
    const pathWithoutProtocolAndLeadingSlash = pathWithoutProtocol.replace(
      /^\//,
      ''
    );
    const pathElements = pathWithoutProtocolAndLeadingSlash.split('/');

    return {
      protocol,
      baseName: pathElements[pathElements.length - 1] as string, // split always returns at least one element, even for empty strings. So this element will always exist
      pathElements,
      path,
      pathWithoutBaseName: path.replace(/\/?[^\/]+$/, ''),
      pathWithoutProtocol
    };
  }

  /**
   * joins args into 1 string and adds a / if necessary
   */
  public static joinPaths(...args: Array<string>): string {
    let result = '';

    for (const path of args) {
      if (path.length === 0) {
        continue;
      }

      if (
        result.length > 0 &&
        result[result.length - 1] !== '/' &&
        path[0] !== '/'
      ) {
        result += '/';
      }

      if (result[result.length - 1] === '/' && path[0] === '/') {
        // necessary to prevent having two slashes in the result
        result += path.substring(1);
      } else {
        result += path;
      }
    }

    return result;
  }

  public static mergeOverlappingPaths(
    path1: string,
    path2: string,
    options: { caseInsensitive: boolean } = { caseInsensitive: false }
  ): string {
    const path1Parts = ArrayUtils.trim(path1.split('/'));
    const path1ComparisonParts = path1Parts.map((part) =>
      options.caseInsensitive ? part.toLowerCase() : part
    );
    const path2Parts = ArrayUtils.trim(path2.split('/'));
    const path2ComparisonParts = path2Parts.map((part) =>
      options.caseInsensitive ? part.toLowerCase() : part
    );

    let path2OverlapStartIndex = -1;

    for (let index = 0; index <= path2ComparisonParts.length; index++) {
      const relevantPath2ComparisonParts = path2ComparisonParts.slice(
        0,
        index + 1
      );

      const relevantPath1ComparisonParts = path1ComparisonParts.slice(
        path1ComparisonParts.length - index - 1
      );

      if (
        this.pathArraysAreEqual(
          relevantPath1ComparisonParts,
          relevantPath2ComparisonParts
        )
      ) {
        path2OverlapStartIndex = index;
      }
    }

    return [
      ...path1Parts,
      ...path2Parts.slice(path2OverlapStartIndex + 1)
    ].join('/');
  }

  public static getFullOnlinePicPath(
    pictureFile: PictureFileDto<IdType, DateType>,
    fullSize: boolean = false,
    mainEntityInfo: PictureMainEntityInfo<IdType> | null,
    host: string
  ): string {
    if (!mainEntityInfo) {
      throw new Error(
        `No mainEntityInfo found for PictureFile ${pictureFile.id}`
      );
    }

    let path = this.joinPaths(
      host,
      'files',
      mainEntityInfo.name,
      mainEntityInfo.id.toString(),
      pictureFile.id,
      pictureFile.file_created
        ? pictureFile.file_created.toString()
        : 'Invalid Date'
    );

    if (!fullSize) {
      path = this.joinPaths(path, 'small');
    } else {
      path = this.joinPaths(path, 'full_size');
    }
    path = this.joinPaths(
      path,
      pictureFile.file_extension ? pictureFile.file_extension : ''
    );
    return path;
  }

  private static pathArraysAreEqual(
    array1: Array<string>,
    array2: Array<string>
  ): boolean {
    if (array1.length !== array2.length) {
      return false;
    }

    return array1.every((item, index) => array2[index] === item);
  }

  private static extractProtocol(path: string): {
    protocol: string | null;
    pathWithoutProtocol: string;
  } {
    const result = /([a-zA-Z:]+)\/\//.exec(path) as [string, string] | null;

    if (!result) {
      return {
        protocol: null,
        pathWithoutProtocol: path
      };
    }

    return {
      pathWithoutProtocol: path.slice(result[0].length),
      protocol: result[1]
    };
  }
}

export type PathDetails = {
  /**
   * The protocol of the path. With the ':'. If the path has no protocol, this will be null
   * e.g. for the path 'file:///home/user/file.txt' this will be 'file:'
   */
  protocol: string | null;

  /**
   * An array of all the element in an path.
   * e.g. for the path '/home/user/file.txt' this will be ['home', 'user', 'file.txt']
   */
  pathElements: Array<string>;

  /**
   * Name of the file or directory.
   * e.g. for the path '/home/user/file.txt' this will be 'file.txt'
   */
  baseName: string;

  /**
   * The path where these details are originating from
   */
  path: string;

  /**
   * If the path is has one element (e.g. '/file.txt') this will be an empty string
   */
  pathWithoutBaseName: string;

  /**
   * path without the protocol prefix
   * e.g. for the path 'cdvfile:///home/user/file.txt' this will be '/home/user/file.txt'
   */
  pathWithoutProtocol: string;
};
