import { autoinject } from 'aurelia-framework';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { AppEntityManager } from '../AppEntityManager';
import { ReportType } from '../ReportType/types';
import { Thing } from '../Thing/types';
import { ApplyThingSectionConfigPropertiesService } from '../ThingSectionConfigProperty/ApplyThingSectionConfigPropertiesService';
import { CurrentUserService } from '../User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../UserCompanySetting/ActiveUserCompanySettingService';
import { AssignReportTypeToProjectService } from './AssignReportTypeToProjectService';
import { ProjectUtils } from './ProjectUtils';
import { Project } from './types';

@autoinject()
export class ProjectCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly assignReportTypeToProjectService: AssignReportTypeToProjectService,
    private readonly applyThingSectionConfigPropertiesService: ApplyThingSectionConfigPropertiesService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly currentUserService: CurrentUserService
  ) {}

  public createProject<TProjectType extends ProjectType>({
    thing,
    name,
    projectType,
    structureTemplateId,
    reportType,
    shadowEntity,
    temporaryGroupName
  }: CreateProjectOptions<TProjectType>): Project {
    const project = this.createProjectEntity({
      thing,
      name,
      projectType,
      structureTemplateId,
      shadowEntity,
      temporaryGroupName
    });

    const thingSections =
      this.entityManager.thingSectionRepository.getOrderedByThingId(
        project.thing
      );
    this.applyThingSectionConfigPropertiesService.createProjectThingSectionsProperties(
      {
        thingSections,
        project
      }
    );

    if (reportType) {
      this.assignReportTypeToProjectService.assignReportTypeToProject(
        project,
        reportType
      );
    }

    return project;
  }

  private createProjectEntity({
    thing,
    name: nameFromParams,
    projectType,
    structureTemplateId,
    shadowEntity,
    temporaryGroupName
  }: Pick<
    CreateProjectOptions<ProjectType>,
    | 'thing'
    | 'name'
    | 'projectType'
    | 'structureTemplateId'
    | 'shadowEntity'
    | 'temporaryGroupName'
  >): Project {
    const name = this.getProjectName({
      name: nameFromParams,
      thing
    });

    if (
      projectType === ProjectType.B1300 ||
      projectType === ProjectType.INSPECT
    ) {
      return this.entityManager.projectRepository.create({
        name,
        projectType,
        thing: thing.id,
        structureTemplateId: structureTemplateId ?? null,
        usergroup: thing.usergroup,
        ownerUserGroupId: thing.ownerUserGroupId,
        shadowEntity,
        temporaryGroupName
      });
    }

    return this.entityManager.projectRepository.create({
      name,
      projectType,
      thing: thing.id,
      usergroup: thing.usergroup,
      ownerUserGroupId: thing.ownerUserGroupId,
      shadowEntity,
      temporaryGroupName
    });
  }

  private getProjectName({
    name,
    thing
  }: {
    name: string | null | undefined;
    thing: Thing;
  }): string | null | undefined {
    if (
      !this.activeUserCompanySettingService.getSettingProperty(
        'general.useKfvPukProjectNameGeneration'
      ) ||
      name !== undefined
    ) {
      return name;
    }

    return ProjectUtils.getKfvPukProjectName({
      currentUser: this.currentUserService.getCurrentUser(),
      thing
    });
  }
}

export type CreateProjectOptions<TProjectType extends ProjectType> =
  AddStructureTemplateIdParam<
    TProjectType,
    {
      thing: Thing;
      name?: string | null;
      projectType: TProjectType;
      reportType: ReportType | null;
      shadowEntity?: boolean;
      temporaryGroupName?: string | null;
    }
  >;

type AddStructureTemplateIdParam<
  TProjectType extends ProjectType,
  T
> = TProjectType extends ProjectType.INSPECT
  ? T & { structureTemplateId: string }
  : TProjectType extends ProjectType.B1300
    ? T & { structureTemplateId: string }
    : T & { structureTemplateId?: null };
