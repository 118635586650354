import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ExportType,
  OperationsExportFormResponse
} from 'common/EndpointTypes/OperationsExportEndpointsHandler';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GroupedProperties } from '../../classes/EntityManager/entities/Property/GroupedPropertyHelper';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProjectProperty } from '../../classes/EntityManager/entities/Property/types';
import { FormProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { TemporaryJoinedProjectHandle } from '../../classes/EntityManager/entities/Project/TemporaryJoinedProjectHandle';
import { Dialogs } from '../../classes/Dialogs';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { FileDownloadService } from '../../services/FileDownloadService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskFormWidget {
  @bindable()
  public processTaskToProject: FormProcessTaskToProject | null = null;

  /** If true, will not expand the expandable-widget on first render. */
  @bindable()
  public collapse: boolean = true;

  @bindable()
  public exportMode: 'docx' | 'pdf' | null = null;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private subscriptionManager: SubscriptionManager;

  private projectHandle: TemporaryJoinedProjectHandle;
  private groupedProperties: GroupedProperties<ProjectProperty> = [];
  private isAttached: boolean = false;
  private ensurePropertiesDisposeCallback: Function | null = null;
  private isConnected: boolean = false;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.projectHandle = new TemporaryJoinedProjectHandle(entityManager);
    this.subscriptionManager = subscriptionManagerService.create();
    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Project,
        context: this,
        expression: 'projectHandle.project'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateProject.bind(this)
    );
    this.updateProject();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
    this.projectHandle.reset();

    this.ensurePropertiesDisposeCallback?.();
  }

  protected processTaskToProjectChanged(): void {
    if (this.isAttached) {
      this.updateProject();
    }
  }

  private updateProject(): void {
    this.projectHandle.project = this.processTaskToProject
      ? this.entityManager.projectRepository.getById(
          this.processTaskToProject.projectId
        )
      : null;

    this.updateProperties();
  }

  private updateProperties(): void {
    if (this.processTaskToProject) {
      this.groupedProperties =
        this.entityManager.propertyRepository.getGroupedPropertiesByProjectId(
          this.processTaskToProject.projectId
        );
    } else {
      this.groupedProperties = [];
    }
  }

  protected handleExpandableContainerStartsExpanding(): void {
    this.projectHandle.enableAutoJoining();
  }

  protected async handleExportClick(): Promise<void> {
    await this.export({
      exportAsPdf: this.exportMode === 'pdf'
    });
  }

  private async export({
    exportAsPdf
  }: {
    exportAsPdf: boolean;
  }): Promise<void> {
    const processTaskToProject = this.processTaskToProject;
    assertNotNullOrUndefined(
      processTaskToProject,
      "can't ProcessTaskFormWidget.handleExportClick without processTaskToProject"
    );

    Dialogs.waitDialog();

    const response =
      await EventAggregatorPromiseHelper.createConnectedPromise<OperationsExportFormResponse>(
        this.eventAggregator,
        new Promise((resolve) => {
          this.socketService.downloadProcessTaskForm(
            {
              processTaskToProjectId: processTaskToProject.id,
              exportType: exportAsPdf ? ExportType.PDF : ExportType.DOCX
            },
            (res) => {
              resolve(res);
            }
          );
        })
      );

    if (response.success) {
      Dialogs.closeAllDialogs();
      void this.fileDownloadService.downloadFileByToken(response.token);
    } else {
      const errorMessageKey = `serverResponses.${
        response.status ? response.status : 'unspecifiedError'
      }`;
      void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
    }
  }
}
