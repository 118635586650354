import { EntityInfo } from '@record-it-npm/synchro-common';

import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';

import { ThingAuthorization } from '../../../../classes/EntityManager/entities/ThingAuthorization/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { thingAuthorizationEntityInfo } from '../../../../classes/EntityManager/entities/ThingAuthorization/thingAuthorizationEntityInfo';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';

export class ThingAuthorizationAdapter
  implements EntityAdapter<ThingAuthorization>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> = new Set();

  constructor(options: ThingAuthorizationAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(thingAuthorization: ThingAuthorization): boolean {
    return this.checkPermission({
      thingAuthorization,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteThingAuthorizations()
    });
  }

  public canEditField(thingAuthorization: ThingAuthorization): boolean {
    return this.checkPermission({
      thingAuthorization,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateThingAuthorizations()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ThingAuthorization,
    ThingAuthorization
  > {
    return thingAuthorizationEntityInfo;
  }

  private checkPermission({
    thingAuthorization,
    checkRolePermission
  }: {
    thingAuthorization: ThingAuthorization;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (thingAuthorization.systemGenerated) {
      return false;
    }

    return EntityAdapterUtils.checkPermissionBasedOnThingId({
      thingId: thingAuthorization.thingId,
      ownerUserGroupId: thingAuthorization.ownerUserGroupId,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

type ThingAuthorizationAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
