import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { reportEntityInfo } from '../../../../classes/EntityManager/entities/Report/reportEntityInfo';
import { Report } from '../../../../classes/EntityManager/entities/Report/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import type { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import type { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { LockedBySignedReportReportIdsComputer } from '../../../../computedValues/computers/LockedBySignedReportReportIdsComputer/LockedBySignedReportReportIdsComputer';

export class ReportAdapter implements EntityAdapter<Report> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly entityManager: AppEntityManager;

  private editableUserGroupIds: Set<string> = new Set();

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private lockedSigningBySignedReportReportIds: Set<string> = new Set();
  private projectIdsWhereUserIsAuthorized: Set<string> = new Set();

  constructor(options: ReportAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.entityManager = options.entityManager;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: LockedBySignedReportReportIdsComputer,
        computeData: {},
        callback: ({ lockedSigningReportIds }) => {
          this.lockedSigningBySignedReportReportIds = lockedSigningReportIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ projectIdsWhereUserIsAuthorized }) => {
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(EntityName.Report, () => {
      updateBindings();
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: () =>
        report.signatureTime == null && this.entityIsEditableUserGroup(report)
    });
  }

  public canEditField(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: () => this.entityIsEditableUserGroup(report)
    });
  }

  public canSignReport(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateReportSignatures()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Report,
    Report
  > {
    return reportEntityInfo;
  }

  private entityIsEditableUserGroup(report: Report): boolean {
    return this.editableUserGroupIds.has(report.ownerUserGroupId);
  }

  private checkPermission({
    report,
    checkRolePermission
  }: {
    report: Report;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (this.lockedSigningBySignedReportReportIds.has(report.id)) {
      return false;
    }

    if (report.ownerProjectId) {
      return EntityAdapterUtils.checkProjectSubEntityPermission({
        entity: report,
        projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
        roleBasedPermissions: this.roleBasedPermissions,
        checkRolePermission
      });
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(report.ownerUserGroupId)
    );
  }
}

export type ReportAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  entityManager: AppEntityManager;
};
