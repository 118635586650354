import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { Picture as PictureEntity } from '../../classes/EntityManager/entities/Picture/types';
import { CustomImg, PictureLoadedEvent } from '../custom-img/custom-img';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import {
  RunSkippedError,
  SerialTask
} from '../../classes/SerialTask/SerialTask';

/**
 * @attribute no-border - add this attribute to disable the default border
 *
 * @event picture-loaded - the picture has been successfully loaded
 */
@autoinject()
export class Picture {
  @bindable public picture: PictureEntity | null = null;

  /** fallback text if there is no picture */
  @bindable public noPictureText: string = '';

  /** set to true to show an orange border if the picture is selected */
  @bindable public showSelection: boolean = false;

  /**
   * set this to false if you want to show that this picture is not editable/clickable
   * just changes the style to show the not-allowed cursor
   */
  @bindable public editable: boolean = true;

  /**
   * set this to false if you want to show that this picture is not editable/clickable
   * just changes the style to show the default cursor
   */
  @bindable public clickable: boolean = true;

  /** set this to true if you want to show the high res version of the picture (instead of just a thumbnail) */
  @bindable public fullSize: boolean = false;

  /** the image elements are dragable by default, but in some situations you don't want this, e.g. in a zoom-box element */
  @bindable public disableDragging: boolean = false;

  private isAttached = false;

  protected errorTextTk: string | null = null;

  private domElement: HTMLElement;

  protected customImgViewModel: CustomImg | null = null;

  protected pictureSources: Array<string> = [];

  private subscriptionManager: SubscriptionManager;

  private loadPictureSourcesTask = new SerialTask<PictureEntity, Array<string>>(
    (picture) => {
      const pictureRevision =
        this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
          picture.id
        );

      let pictureFile: PictureFile | null = null;

      if (pictureRevision) {
        pictureFile =
          this.entityManager.pictureFileRepository.getPictureFileToDisplayByRevisionId(
            pictureRevision.id
          );
      } else {
        pictureFile =
          this.entityManager.pictureFileRepository.getPictureFileToDisplayByPictureId(
            picture.id
          );
      }
      return this.pictureFilePathService.getPicturePreviews(
        pictureFile,
        this.fullSize
      );
    }
  );

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected) void this.loadPicture(this.picture);
      })
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      () => {
        void this.loadPicture(this.picture);
      }
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureRevision,
      () => {
        void this.loadPicture(this.picture);
      }
    );
    this.isAttached = true;
    void this.loadPicture(this.picture);
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.isAttached = false;
  }

  protected pictureChanged(): void {
    if (this.isAttached) {
      void this.loadPicture(this.picture);
    }
  }

  public getNaturalDimensions(): { width: number; height: number } {
    return (
      this.customImgViewModel?.getNaturalDimensions() ?? { width: 0, height: 0 }
    );
  }

  protected firePictureLoadedEvent(event: PictureLoadedEvent): void {
    DomEventHelper.fireEvent<PictureLoadedEvent>(this.domElement, {
      name: 'picture-loaded',
      detail: event.detail
    });
  }

  protected getDragstartFunction(
    disableDragging: boolean
  ): ((event: DragEvent) => boolean) | null {
    return disableDragging
      ? (event: DragEvent) => {
          event.preventDefault();
          return false;
        }
      : null;
  }

  private async loadPicture(picture: PictureEntity | null): Promise<void> {
    this.errorTextTk = null;

    if (!picture) {
      this.pictureSources = [];
      return;
    }

    try {
      this.pictureSources = await this.loadPictureSourcesTask.run(picture);
    } catch (e) {
      if (!(e instanceof RunSkippedError)) throw e;
    }

    if (!this.pictureSources.length)
      this.errorTextTk = 'picture.picture.pictureHasNoThumbnail';
  }

  protected handleLoadingError(): void {
    this.errorTextTk = 'picture.picture.pictureLoadingError';

    DomEventHelper.fireEvent(this.domElement, {
      name: 'picture-error',
      detail: null
    });
  }
}
