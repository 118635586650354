import _ from 'lodash';
import { EntityFilterSubscribeOptions } from '@record-it-npm/synchro-client';
import {
  RoleBasedPermissions,
  RoleBasedPermissionsOptions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { MapUtils } from 'common/Utils/MapUtils/MapUtils';
import { ObjectUtils } from 'common/Utils/ObjectUtils/ObjectUtils';
import { SetUtils } from 'common/Utils/SetUtils/SetUtils';
import { SessionService } from '../../../services/SessionService';
import {
  Disposable,
  DisposableContainer
} from '../../Utils/DisposableContainer';
import { SubscriptionUtils } from '../../Utils/SubscriptionUtils/SubscriptionUtils';
import { IUtilsRateLimitedFunction, Utils } from '../../Utils/Utils';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../entities/AppEntityManager';
import { EntityName } from '../entities/types';
import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';
import {
  PersonIdToThingIdsComputerUtils,
  PersonIdToThingIdsMap
} from '../../../computedValues/computers/PersonIdToThingIdsComputer/PersonIdToThingIdsComputerUtils';

/**
 * A collection of function/data required to filter all entities
 */
export class FilterData {
  private readonly entityManager: AppEntityManager;
  private readonly sessionService: SessionService;

  private readonly rateLimitedSubscriptionCallbacks =
    new Set<IUtilsRateLimitedFunction>();

  private readonly noPendingSubscriptionsPromiseContainer =
    new PromiseContainer<undefined>();

  private currentUserId: string | null = null;

  private thingIdToHasThingAuthorization: Map<string, boolean> = new Map();
  private processTaskGroupIdToHasProcessTaskGroupAuthorization: Map<
    string,
    boolean
  > = new Map();

  private thingIdToProcessTaskGroupIds: Map<string, Array<string>> = new Map();

  private thingGroupIdToThingIds: Map<string | null, Array<string>> = new Map();

  private personIdToThingIds: PersonIdToThingIdsMap = new Map();

  private availableThingIds: Set<string> = new Set();

  private availableProcessTaskGroupIds: Set<string> = new Set();

  private roleBasedPermissionsOptions: RoleBasedPermissionsOptions | null =
    null;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor({
    entityManager,
    sessionService
  }: {
    entityManager: AppEntityManager;
    sessionService: SessionService;
  }) {
    this.entityManager = entityManager;
    this.sessionService = sessionService;
  }

  public async waitForSubscriptionsToBeFinished(): Promise<void> {
    if (this.subscriptionCallbacksArePending()) {
      await this.noPendingSubscriptionsPromiseContainer.create();
    }
  }

  public subscribe(
    options: EntityFilterSubscribeOptions<AppSynchronizationEnvironmentTypes>
  ): Disposable {
    const disposableContainer = new DisposableContainer();

    const updateAllEntitiesRateLimited = Utils.rateLimitFunction(() => {
      options.updateAllEntities();
      this.handleSubscriptionResolved();
    }, 125);
    this.registerRateLimitedCallback({
      disposableContainer,
      rateLimitedCallback: updateAllEntitiesRateLimited
    });
    disposableContainer.add(updateAllEntitiesRateLimited.toCancelDisposable());

    this.sessionService.subscribeToCurrentUserChanged(this, () => {
      const { currentUserIdChanged } = this.updateCurrentUserId();
      const { thingIdToHasThingAuthorizationChanged } = currentUserIdChanged
        ? this.updateThingIdToHasThingAuthorization()
        : { thingIdToHasThingAuthorizationChanged: false };

      if (currentUserIdChanged || thingIdToHasThingAuthorizationChanged) {
        updateAllEntitiesRateLimited();
      }
    });
    this.updateCurrentUserId();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.ThingAuthorization],
      callback: () => {
        const { thingIdToHasThingAuthorizationChanged } =
          this.updateThingIdToHasThingAuthorization();

        if (thingIdToHasThingAuthorizationChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateThingIdToHasThingAuthorization();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.ProcessTaskGroupAuthorization],
      callback: () => {
        const { processTaskGroupIdToHasProcessTaskGroupAuthorizationChanged } =
          this.updateProcessTaskGroupIdToHasProcessTaskGroupAuthorization();

        if (processTaskGroupIdToHasProcessTaskGroupAuthorizationChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateProcessTaskGroupIdToHasProcessTaskGroupAuthorization();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.ProcessTaskGroup],
      callback: () => {
        const { availableProcessTaskGroupIdsChanged } =
          this.updateAvailableProcessTaskGroupIds();

        if (availableProcessTaskGroupIdsChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateAvailableProcessTaskGroupIds();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.User, EntityName.UserRole, EntityName.UserGroup],
      callback: () => {
        const { roleBasedPermissionsChanged } =
          this.updateRoleBasedPermissions();

        if (roleBasedPermissionsChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateRoleBasedPermissions();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.Thing],
      callback: () => {
        const { thingGroupIdToThingIdsChanged } =
          this.updateThingGroupIdToThingIds();
        const { personIdToThingIdsChanged } = thingGroupIdToThingIdsChanged
          ? this.updatePersonIdToThingIds()
          : { personIdToThingIdsChanged: false };

        const { availableThingIdsChanged } = this.updateAvailableThingIds();

        if (
          thingGroupIdToThingIdsChanged ||
          personIdToThingIdsChanged ||
          availableThingIdsChanged
        ) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateThingGroupIdToThingIds();
    this.updateAvailableThingIds();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.ProcessTask],
      callback: () => {
        const { thingIdToProcessTaskGroupIdsChanged } =
          this.updateThingIdToProcessTaskGroupIds();

        if (thingIdToProcessTaskGroupIdsChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updateThingIdToProcessTaskGroupIds();

    this.subscribeToMultipleModelChanges({
      disposableContainer,
      entityNames: [EntityName.PropertyToPerson],
      callback: () => {
        const { personIdToThingIdsChanged } = this.updatePersonIdToThingIds();
        if (personIdToThingIdsChanged) {
          updateAllEntitiesRateLimited();
        }
      }
    });
    this.updatePersonIdToThingIds();

    return {
      dispose: () => {
        this.sessionService.removeEventListenersByContext(this);
        disposableContainer.disposeAll();
      }
    };
  }

  public thingHasAuthorization({
    thingId,
    userGroupId
  }: {
    thingId: string;
    userGroupId: string;
  }): boolean {
    if (this.thingIdToHasThingAuthorization.get(thingId)) {
      return true;
    }

    if (this.canSeeEntitiesWithoutThingAuthorization({ userGroupId })) {
      return this.availableThingIds.has(thingId);
    }

    const processTaskGroupIds = this.thingIdToProcessTaskGroupIds.get(thingId);
    if (!processTaskGroupIds || processTaskGroupIds.length === 0) {
      return false;
    }

    return processTaskGroupIds.some((processTaskGroupId) => {
      return this.processTaskGroupHasAuthorization({
        processTaskGroupId,
        userGroupId
      });
    });
  }

  public processTaskGroupHasAuthorization({
    processTaskGroupId,
    userGroupId
  }: {
    processTaskGroupId: string;
    userGroupId: string;
  }): boolean {
    if (
      this.processTaskGroupIdToHasProcessTaskGroupAuthorization.get(
        processTaskGroupId
      )
    ) {
      return true;
    }

    if (
      this.canSeeEntitiesWithoutProcessTaskGroupAuthorization({ userGroupId })
    ) {
      return this.availableProcessTaskGroupIds.has(processTaskGroupId);
    }

    return false;
  }

  public getThingIdsForThingGroupId({
    thingGroupId
  }: {
    thingGroupId: string;
  }): Array<string> {
    return this.thingGroupIdToThingIds.get(thingGroupId) ?? [];
  }

  public getThingIdsForPersonId({
    personId
  }: {
    personId: string;
  }): Array<string> {
    return this.personIdToThingIds.get(personId) ?? [];
  }

  public canSeePersonsWithoutExplicitAuthorization({
    userGroupId
  }: {
    userGroupId: string;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroupId)
      .getCanSeePersonsWithoutExplicitAuthorization();
  }

  private canSeeEntitiesWithoutThingAuthorization({
    userGroupId
  }: {
    userGroupId: string;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroupId)
      .getCanSeeEntitiesWithoutThingAuthorization();
  }

  private canSeeEntitiesWithoutProcessTaskGroupAuthorization({
    userGroupId
  }: {
    userGroupId: string;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroupId)
      .getCanSeeEntitiesWithoutProcessTaskGroupAuthorization();
  }

  private updateCurrentUserId(): { currentUserIdChanged: boolean } {
    const newCurrentUserId =
      this.sessionService.getCurrentUserIdWithoutWaitingForLoad();

    if (newCurrentUserId === this.currentUserId) {
      return { currentUserIdChanged: false };
    }

    this.currentUserId = newCurrentUserId;
    return { currentUserIdChanged: true };
  }

  private updateThingIdToHasThingAuthorization(): {
    thingIdToHasThingAuthorizationChanged: boolean;
  } {
    const thingIdToHasThingAuthorization =
      this.createThingIdToHasThingAuthorization();

    if (
      MapUtils.mapsAreEqual({
        a: this.thingIdToHasThingAuthorization,
        b: thingIdToHasThingAuthorization,
        valuesAreEqual: ({ a, b }) => a === b
      })
    ) {
      return { thingIdToHasThingAuthorizationChanged: false };
    }

    this.thingIdToHasThingAuthorization = thingIdToHasThingAuthorization;
    return { thingIdToHasThingAuthorizationChanged: true };
  }

  private createThingIdToHasThingAuthorization(): Map<string, boolean> {
    if (!this.currentUserId) {
      return new Map();
    }

    const thingAuthorizations = this.entityManager.thingAuthorizationRepository
      .getAllIncludingInvisibleEntities()
      .filter((authorization) => authorization.userId === this.currentUserId);

    return new Map(
      thingAuthorizations.map((authorization) => [
        authorization.thingId,
        !!authorization
      ])
    );
  }

  private updateProcessTaskGroupIdToHasProcessTaskGroupAuthorization(): {
    processTaskGroupIdToHasProcessTaskGroupAuthorizationChanged: boolean;
  } {
    const processTaskGroupIdToHasProcessTaskGroupAuthorization =
      this.createProcessTaskGroupIdToHasProcessTaskGroupAuthorization();

    if (
      MapUtils.mapsAreEqual({
        a: this.processTaskGroupIdToHasProcessTaskGroupAuthorization,
        b: processTaskGroupIdToHasProcessTaskGroupAuthorization,
        valuesAreEqual: ({ a, b }) => a === b
      })
    ) {
      return {
        processTaskGroupIdToHasProcessTaskGroupAuthorizationChanged: false
      };
    }

    this.processTaskGroupIdToHasProcessTaskGroupAuthorization =
      processTaskGroupIdToHasProcessTaskGroupAuthorization;
    return {
      processTaskGroupIdToHasProcessTaskGroupAuthorizationChanged: true
    };
  }

  private createProcessTaskGroupIdToHasProcessTaskGroupAuthorization(): Map<
    string,
    boolean
  > {
    if (!this.currentUserId) {
      return new Map();
    }

    const processTaskGroupAuthorizations =
      this.entityManager.processTaskGroupAuthorizationRepository
        .getAllIncludingInvisibleEntities()
        .filter((authorization) => authorization.userId === this.currentUserId);

    return new Map(
      processTaskGroupAuthorizations.map((authorization) => [
        authorization.ownerProcessTaskGroupId,
        !!authorization
      ])
    );
  }

  private updateRoleBasedPermissions(): {
    roleBasedPermissionsChanged: boolean;
  } {
    const options = this.createRoleBasedPermissionsOptions();

    if (_.isEqual(options, this.roleBasedPermissionsOptions)) {
      return { roleBasedPermissionsChanged: false };
    }

    this.roleBasedPermissionsOptions = ObjectUtils.copyDeep(options);
    this.roleBasedPermissions = new RoleBasedPermissions(options);

    return { roleBasedPermissionsChanged: true };
  }

  private createRoleBasedPermissionsOptions(): RoleBasedPermissionsOptions {
    const user = this.currentUserId
      ? this.entityManager.userRepository.getByIdIncludingInvisibleEntities(
          this.currentUserId
        )
      : null;

    const userGroupsOfUser = user
      ? this.entityManager.userGroupRepository
          .getAllIncludingInvisibleEntities()
          .filter((userGroup) => {
            return userGroup.userSpecs.some((userSpec) => {
              return userSpec._id === user.id;
            });
          })
      : [];

    const userGroupIds = new Set(
      userGroupsOfUser.map((userGroup) => userGroup.id)
    );

    const userRolesOfUserGroups = this.entityManager.userRoleRepository
      .getAllIncludingInvisibleEntities()
      .filter((userRole) => userGroupIds.has(userRole.ownerUserGroupId));

    const userRoleToUsersOfUser = user
      ? this.entityManager.userRoleToUserRepository
          .getAllIncludingInvisibleEntities()
          .filter((userRoleToUser) => {
            return (
              userRoleToUser.userId === user.id &&
              userGroupIds.has(userRoleToUser.ownerUserGroupId)
            );
          })
      : [];

    return {
      user,
      userGroupsOfUser,
      userRolesOfUserGroups,
      userRoleToUsersOfUser
    };
  }

  private updateThingGroupIdToThingIds(): {
    thingGroupIdToThingIdsChanged: boolean;
  } {
    const thingGroupIdToThingIds = this.createThingGroupIdToThingIds();

    if (
      this.idMapsAreEqual({
        previousIdMap: this.thingGroupIdToThingIds,
        newIdMap: thingGroupIdToThingIds
      })
    ) {
      return { thingGroupIdToThingIdsChanged: false };
    }

    this.thingGroupIdToThingIds = thingGroupIdToThingIds;
    return { thingGroupIdToThingIdsChanged: true };
  }

  private createThingGroupIdToThingIds(): Map<string | null, Array<string>> {
    const things =
      this.entityManager.thingRepository.getAllIncludingInvisibleEntities();

    const thingGroupIdToThingIds = new Map<string | null, Array<string>>();

    for (const thing of things) {
      MapUtils.addIdsToIdMap({
        idsToAdd: [thing.id],
        key: thing.thingGroupId,
        idMap: thingGroupIdToThingIds
      });
    }

    return thingGroupIdToThingIds;
  }

  private updatePersonIdToThingIds(): { personIdToThingIdsChanged: boolean } {
    const personIdToThingIds = this.createPersonIdToThingIds();

    if (
      this.idMapsAreEqual({
        previousIdMap: this.personIdToThingIds,
        newIdMap: personIdToThingIds
      })
    ) {
      return { personIdToThingIdsChanged: false };
    }

    this.personIdToThingIds = personIdToThingIds;
    return { personIdToThingIdsChanged: true };
  }

  private createPersonIdToThingIds(): PersonIdToThingIdsMap {
    return PersonIdToThingIdsComputerUtils.createPersonIdToThingIdsMap({
      thingGroupIdToThingIds: this.thingGroupIdToThingIds,
      propertyToPersons:
        this.entityManager.propertyToPersonRepository.getAllIncludingInvisibleEntities(),
      getPropertyById: (id) =>
        this.entityManager.propertyRepository.getByIdIncludingInvisibleEntities(
          id
        )
    });
  }

  private updateThingIdToProcessTaskGroupIds(): {
    thingIdToProcessTaskGroupIdsChanged: boolean;
  } {
    const thingIdToProcessTaskGroupIds =
      this.createThingIdToProcessTaskGroupIds();

    if (
      this.idMapsAreEqual({
        previousIdMap: this.thingIdToProcessTaskGroupIds,
        newIdMap: thingIdToProcessTaskGroupIds
      })
    ) {
      return { thingIdToProcessTaskGroupIdsChanged: false };
    }

    this.thingIdToProcessTaskGroupIds = thingIdToProcessTaskGroupIds;
    return { thingIdToProcessTaskGroupIdsChanged: true };
  }

  private createThingIdToProcessTaskGroupIds(): Map<string, Array<string>> {
    const processTasks =
      this.entityManager.processTaskRepository.getAllIncludingInvisibleEntities();

    const thingIdToProcessTaskGroupIds = new Map<string, Array<string>>();

    for (const processTask of processTasks) {
      MapUtils.addIdsToIdMap({
        key: processTask.thingId,
        idsToAdd: [processTask.ownerProcessTaskGroupId],
        idMap: thingIdToProcessTaskGroupIds
      });
    }

    return thingIdToProcessTaskGroupIds;
  }

  private updateAvailableThingIds(): { availableThingIdsChanged: boolean } {
    const availableThingIds = new Set(
      this.entityManager.thingRepository
        .getAllIncludingInvisibleEntities()
        .map((thing) => thing.id)
    );

    if (
      SetUtils.setsAreEqual({
        a: availableThingIds,
        b: this.availableThingIds
      })
    ) {
      return { availableThingIdsChanged: false };
    }

    this.availableThingIds = availableThingIds;
    return { availableThingIdsChanged: true };
  }

  private updateAvailableProcessTaskGroupIds(): {
    availableProcessTaskGroupIdsChanged: boolean;
  } {
    const availableProcessTaskGroupIds = new Set(
      this.entityManager.processTaskGroupRepository
        .getAllIncludingInvisibleEntities()
        .map((processTaskGroup) => processTaskGroup.id)
    );

    if (
      SetUtils.setsAreEqual({
        a: availableProcessTaskGroupIds,
        b: this.availableProcessTaskGroupIds
      })
    ) {
      return { availableProcessTaskGroupIdsChanged: false };
    }

    this.availableProcessTaskGroupIds = availableProcessTaskGroupIds;
    return { availableProcessTaskGroupIdsChanged: true };
  }

  private idMapsAreEqual<TKey>({
    previousIdMap,
    newIdMap
  }: {
    previousIdMap: Map<TKey, Array<string>>;
    newIdMap: Map<TKey, Array<string>>;
  }): boolean {
    return MapUtils.mapsAreEqual({
      a: previousIdMap,
      b: newIdMap,
      valuesAreEqual: ({ a, b }) => {
        return _.isEqual(a, b);
      }
    });
  }

  private subscribeToMultipleModelChanges({
    entityNames,
    disposableContainer,
    callback
  }: {
    entityNames: Array<EntityName>;
    disposableContainer: DisposableContainer;
    callback: () => void;
  }): void {
    const subscriptionResult =
      SubscriptionUtils.subscribeToMultipleModelChanges({
        entityManager: this.entityManager,
        entityNames,
        listenToVisibilityChanges: false,
        callback: () => {
          callback();
          this.handleSubscriptionResolved();
        },
        rateInterval: 125
      });

    this.registerRateLimitedCallback({
      disposableContainer,
      rateLimitedCallback: subscriptionResult.rateLimitedCallback
    });

    disposableContainer.add(subscriptionResult);
    disposableContainer.add(
      subscriptionResult.rateLimitedCallback.toCancelDisposable()
    );
  }

  private handleSubscriptionResolved(): void {
    if (!this.subscriptionCallbacksArePending()) {
      this.noPendingSubscriptionsPromiseContainer.resolveAll(undefined);
    }
  }

  private registerRateLimitedCallback({
    disposableContainer,
    rateLimitedCallback
  }: {
    disposableContainer: DisposableContainer;
    rateLimitedCallback: IUtilsRateLimitedFunction;
  }): void {
    this.rateLimitedSubscriptionCallbacks.add(rateLimitedCallback);

    disposableContainer.add({
      dispose: () => {
        this.rateLimitedSubscriptionCallbacks.delete(rateLimitedCallback);
      }
    });
  }

  private subscriptionCallbacksArePending(): boolean {
    for (const callback of this.rateLimitedSubscriptionCallbacks.values()) {
      if (callback.isPending()) {
        return true;
      }
    }

    return false;
  }
}
