import { MapUtils } from 'common/Utils/MapUtils/MapUtils';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { PropertyToPerson } from '../../../classes/EntityManager/entities/PropertyToPerson/types';

export class PersonIdToThingIdsComputerUtils {
  private constructor() {}

  public static createPersonIdToThingIdsMap({
    getPropertyById,
    propertyToPersons,
    thingGroupIdToThingIds
  }: {
    getPropertyById: (id: string) => Property | null;
    propertyToPersons: Array<PropertyToPerson>;
    thingGroupIdToThingIds: Map<string | null, Array<string>>;
  }): PersonIdToThingIdsMap {
    const personIdToThingIds: PersonIdToThingIdsMap = new Map();

    for (const propertyToPerson of propertyToPersons) {
      const property = getPropertyById(propertyToPerson.propertyId);

      if (property?.thing) {
        MapUtils.addIdsToIdMap({
          key: propertyToPerson.personId,
          idsToAdd: [property.thing],
          idMap: personIdToThingIds
        });
      } else if (property?.thingGroupId) {
        MapUtils.addIdsToIdMap({
          key: propertyToPerson.personId,
          idsToAdd: thingGroupIdToThingIds.get(property.thingGroupId) ?? [],
          idMap: personIdToThingIds
        });
      }
    }

    return personIdToThingIds;
  }
}

export type PersonIdToThingIdsMap = Map<string, Array<string>>;
