import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { Defect } from '../../../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { defectEntityInfo } from '../../../../classes/EntityManager/entities/Defect/defectEntityInfo';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';

export class DefectAdapter implements EntityAdapter<Defect> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> = new Set();
  private currentUser: User | null = null;

  constructor(options: DefectAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public canDeleteEntity(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanDeleteDefects()
    });
  }

  public canEditDefect(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public canEditPictures(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public canEditProperties(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public canAddComments(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefects() ||
        this.defectIsAssignedToCurrentUser(defect)
    });
  }

  public canSetDefectStatusToNone(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefects() ||
        this.defectIsAssignedToCurrentUser(defect)
    });
  }

  public canSetDefectStatusToOpen(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public canSetDefectStatusToProcessed(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefects() ||
        this.defectIsAssignedToCurrentUser(defect)
    });
  }

  public canSetDefectStatusToDone(defect: Defect): boolean {
    return this.checkPermission({
      defect,
      checkRolePermission: (permissions) => permissions.getCanUpdateDefects()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Defect,
    Defect
  > {
    return defectEntityInfo;
  }

  private checkPermission({
    defect,
    checkRolePermission
  }: {
    defect: Defect;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkPermissionBasedOnThingId({
      thingId: defect.ownerThingId,
      ownerUserGroupId: defect.ownerUserGroupId,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }

  private defectIsAssignedToCurrentUser(defect: Defect): boolean {
    if (!this.currentUser) {
      return false;
    }

    return this.currentUser.id === defect.assigneeId;
  }
}

type DefectAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
