import { assertNotNullOrUndefined } from 'common/Asserts';
import { Disposable } from './DisposableContainer';

export class Utils {
  private constructor() {}

  public static redirectToNewLocation(location: string): void {
    window.location.assign(location);
  }

  public static getRelativeElementOffset(
    element: HTMLElement,
    relativeElement: HTMLElement
  ): { top: number; left: number } {
    const elementOffset = this.getElementOffset(element);
    const relativeOffset = this.getElementOffset(relativeElement);

    return {
      top: elementOffset.top - relativeOffset.top,
      left: elementOffset.left - relativeOffset.left
    };
  }

  /**
   * since jquery.offset() can't handle our layout or is generally broken, we have to implement it ourselves
   */
  public static getElementOffset(element: HTMLElement): {
    top: number;
    left: number;
  } {
    const offset = { top: 0, left: 0 };
    let currentElement: HTMLElement | null = element;

    do {
      if (currentElement.offsetTop) {
        offset.top += currentElement.offsetTop;
      }

      if (currentElement.offsetLeft) {
        offset.left += currentElement.offsetLeft;
      }
    } while (
      (currentElement = currentElement.offsetParent as HTMLElement | null)
    );

    return offset;
  }

  public static validateEmail(email: string): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  public static validatePassword(password: string): boolean {
    // return /(?=.{6,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z]).*/.test(password);
    // return /(?=.{8,})(?=.*?[A-Z])(?=.*?[a-z]).*/.test(password); // 8 characters, at least one uppercase
    return /(?=.{8,})(?=.*?[A-Z]).*/.test(password); // 8 characters, at least one uppercase
  }

  public static wait(timeout: number = 20): Promise<void> {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, timeout);
    });
  }

  public static asyncFunctionInTimeoutWrapper(
    funktion: () => Promise<void>,
    timeout = 20,
    thisContext: any = this
  ): Promise<void> {
    return new Promise((res, rej) => {
      setTimeout(() => {
        void funktion.apply(thisContext).then(
          () => {
            res();
          },
          (reason) => {
            rej(reason);
          }
        );
      }, timeout);
    });
  }

  public static debounceFunction<
    F extends (...args: Array<any>) => void | Promise<void>
  >(funktion: F, debounceTime: number): IUtilsDebouncedFunction<F> {
    const f: IUtilsDebouncedFunction<F> = function () {
      f._lastArguments = arguments as unknown as Parameters<F>;

      if (f._timeoutInfo) {
        f.cancel();
      }

      const id = window.setTimeout(() => {
        f._timeoutInfo = null;
        f._callCallback();
      }, debounceTime);

      f._timeoutInfo = { id, clearTimeout };
    };

    f._callCallback = () => {
      if (f._lastArguments) {
        void funktion.apply(window, f._lastArguments);
      }

      f._lastArguments = null;
    };

    f._timeoutInfo = null;
    f._lastArguments = null;

    f.cancel = () => {
      if (f._timeoutInfo) {
        f._timeoutInfo.clearTimeout.call(window, f._timeoutInfo.id);
      }

      f._timeoutInfo = null;
    };

    f.isPending = () => {
      return !!f._timeoutInfo;
    };

    f.callImmediatelyIfPending = () => {
      if (f.isPending()) {
        f.cancel();
        f._callCallback();
      }
    };

    return f;
  }

  /**
   * prevents the function `f` to be called multiple times in the `rateInterval`
   * each function call in the rateInterval will be combined to a single call
   */
  public static rateLimitFunction<
    F extends (...args: Array<any>) => void | Promise<void>
  >(func: F, rateInterval: number): IUtilsRateLimitedFunction<F> {
    const f: IUtilsRateLimitedFunction<F> = function () {
      f._lastArguments = arguments as unknown as Parameters<F>;

      if (!f._timeoutInfo) {
        const id = window.setTimeout(() => {
          f._timeoutInfo = null;
          f._callCallback();
        }, rateInterval);
        f._timeoutInfo = { id, clearTimeout };
      }
    };

    f._callCallback = () => {
      if (f._lastArguments) {
        void func.apply(window, f._lastArguments);
      }
      f._lastArguments = null;
    };

    f._timeoutInfo = null;
    f._lastArguments = null;

    f.cancel = function () {
      if (f._timeoutInfo) {
        f._timeoutInfo.clearTimeout.call(window, f._timeoutInfo.id);
      }
      f._timeoutInfo = null;
    };

    f.isPending = function () {
      return !!f._timeoutInfo;
    };

    f.callImmediatelyIfPending = function () {
      if (f.isPending()) {
        f.cancel();
        f._callCallback();
      }
    };

    f.toCallImmediatelyIfPendingDisposable = function () {
      return {
        dispose: () => {
          f.callImmediatelyIfPending();
        }
      };
    };

    f.toCancelDisposable = function () {
      return {
        dispose: () => {
          f.cancel();
        }
      };
    };

    return f;
  }

  /**
   * moves an item in an array to a new index, mutates the array
   */
  public static moveInArray(
    arr: Array<any>,
    oldIndex: number,
    newIndex: number
  ): Array<any> {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  /**
   * limits the number of items in an array, cuts off at the beginning
   */
  public static limitArrayToNumberOfLastItems<T>(
    array: Array<T>,
    maxNumberOfItems: number
  ): Array<T> {
    let deletedItems: Array<T> = [];
    const arrayLength = array.length;
    if (arrayLength > maxNumberOfItems) {
      deletedItems = array.splice(0, arrayLength - maxNumberOfItems);
    }
    return deletedItems;
  }

  public static b64toBlob(
    b64Data: string,
    contentType?: string,
    sliceSize?: number
  ): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data.replace(/^data:(.+)?;base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static arraysAreEqualWithWildcards(
    array1: Array<string>,
    array2: Array<string>,
    wildcardCharacter: string = '*'
  ): boolean {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      if (
        array1[i] !== wildcardCharacter &&
        array2[i] !== wildcardCharacter &&
        array1[i] !== array2[i]
      ) {
        return false;
      }
    }
    return true;
  }

  public static getFilePathComponents(path: string): {
    name: string | null;
    extension: string | null;
  } {
    const extension = Utils.getExtensionFromPath(path);
    // -1 because there is also a dot before the extension
    const name = extension
      ? path.substr(0, path.length - extension.length - 1)
      : path;

    return {
      name: name || null,
      extension
    };
  }

  public static getExtensionFromPath(path: string): string | null {
    const res = /[^\\\/]\.([^.]+)$/.exec(path);
    return res?.[1] ?? null;
  }

  public static getRequiredViewModelOfElement<T>(element: Element): T {
    const viewModel = this.getViewModelOfElement<T>(element);
    assertNotNullOrUndefined(viewModel, 'no viewModel found');
    return viewModel;
  }

  public static getViewModelOfElement<T>(element: Element): T | null {
    const e = element as any;
    return e.au && e.au.controller ? e.au.controller.viewModel : null;
  }

  public static parseNumberWithUnit(
    str: string
  ): { value: number; unit: string } | null {
    const res = /(-?(?:\d+(?:\.\d*)?)|(?:\.\d+))(.*)/.exec(str);
    const numberString = res?.[1];
    const unit = res?.[2];

    if (numberString != null && unit != null) {
      return {
        value: parseFloat(numberString),
        unit
      };
    } else {
      return null;
    }
  }

  /**
   * compares if both routes mean the same,
   * e.g. #/project/123 is equal to /project/123
   */
  public static compareRoutes(
    route1: string | null,
    route2: string | null
  ): boolean {
    return this.trimRoute(route1) === this.trimRoute(route2);
  }

  /**
   * e.g. #/project/123 will return /project/123
   */
  public static trimRoute(route: string | null): string | null {
    if (route && route[0] === '#') {
      // currentRouteFragment has no leading '#'
      return route.substr(1);
    } else {
      return route;
    }
  }

  public static getClassNameForObject(object: Object): string {
    const defaultClassName = 'UnknownClass';

    if (object.constructor) {
      const res = /function ([^(]*)\(/.exec(object.constructor.toString());
      return res && res[1] ? res[1] : defaultClassName;
    } else {
      return defaultClassName;
    }
  }

  public static extractIdFromPictureFileName(
    pictureFileName: string
  ): string | void {
    const pictureFileNameSplit = pictureFileName.split(/^[^_]*_([^.]*)/);
    if (pictureFileNameSplit.length < 2) return;
    return pictureFileNameSplit[1];
  }

  public static deepFreeze(object: Record<string, any>): void {
    // Retrieve the property names defined on object
    const propNames = Object.getOwnPropertyNames(object);

    // Freeze properties before freezing self
    for (const name of propNames) {
      const value = object[name];
      if (value && typeof value === 'object') this.deepFreeze(value);
    }

    Object.freeze(object);
  }

  public static pickByKeys(
    object: Record<string, any>,
    keys: Array<string>
  ): Record<string, any> {
    return keys.reduce(
      (obj, key) => {
        if (object && object.hasOwnProperty(key)) {
          obj[key] = object[key];
        }
        return obj;
      },
      {} as Record<string, any>
    );
  }

  public static walkThroughParentElements(
    element: Element | null,
    callback: (element: Element) => boolean
  ): void {
    let currentElement = element;
    while (
      currentElement &&
      !(currentElement instanceof HTMLDocument) &&
      callback(currentElement)
    ) {
      currentElement = currentElement.parentElement;
    }
  }

  public static groupBy<T, K>(
    values: Readonly<Array<T>>,
    keyGetter: (value: T) => K
  ): Map<K, Array<T>> {
    const map: Map<K, Array<T>> = new Map();

    for (const value of values) {
      const key = keyGetter(value);

      const group = map.get(key);
      if (group) {
        group.push(value);
      } else {
        map.set(key, [value]);
      }
    }

    return map;
  }

  public static recordToMap<TKey extends string, TValue>(
    record: Record<TKey, TValue>
  ): Map<TKey, TValue> {
    const entries = Object.entries(record) as Array<[TKey, TValue]>;
    return new Map(entries);
  }

  public static mapToRecord<TKey extends string, TValue>(
    map: Map<TKey, TValue>
  ): Record<TKey, TValue> {
    return Array.from(map.entries()).reduce(
      (record, [key, value]) => {
        record[key] = value;
        return record;
      },
      {} as Record<TKey, TValue>
    );
  }
}

export interface IUtilsDebouncedFunction<
  F extends (...args: Array<any>) => void | Promise<void> = (
    ...args: Array<any>
  ) => void | Promise<void>
> {
  (...args: Parameters<F>): void;

  /* cancels the pending func call */
  cancel: () => void;
  isPending: () => boolean;
  callImmediatelyIfPending: () => void;

  _callCallback: () => void;

  /**
   * we also save the clearTimeout here for more test stability
   * this is needed because it can happen, that we mix an id from the normal window.clearTimeout with the sinon override from useFakeTimers
   */
  _timeoutInfo: { id: number; clearTimeout: (id: number) => void } | null;

  _lastArguments: Parameters<F> | null;
}

export interface IUtilsRateLimitedFunction<
  F extends (...args: Array<any>) => void | Promise<void> = (
    ...args: Array<any>
  ) => void | Promise<void>
> {
  (...args: Parameters<F>): void;

  /* cancels the pending func call */
  cancel: () => void;
  isPending: () => boolean;
  callImmediatelyIfPending: () => void;

  toCancelDisposable: () => Disposable;
  toCallImmediatelyIfPendingDisposable: () => Disposable;

  _callCallback: () => void;

  /**
   * we also save the clearTimeout here for more test stability
   * this is needed because it can happen, that we mix an id from the normal window.clearTimeout with the sinon override from useFakeTimers
   */
  _timeoutInfo: { id: number; clearTimeout: (id: number) => void } | null;

  _lastArguments: Parameters<F> | null;
}
