import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { thingGroupEntityInfo } from '../../../../classes/EntityManager/entities/ThingGroup/thingGroupEntityInfo';
import { ThingGroup } from '../../../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ThingGroupAdapter implements EntityAdapter<ThingGroup> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing: Set<string> =
    new Set();

  constructor(options: ThingGroupAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({
          thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing
        }) => {
          this.thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing =
            thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(thingGroup: ThingGroup): boolean {
    return this.checkPermission({
      thingGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateThingGroups()
    });
  }

  public canDeleteEntity(thingGroup: ThingGroup): boolean {
    return this.checkPermission({
      thingGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteThingGroups()
    });
  }

  public canEditProperties(thingGroup: ThingGroup): boolean {
    return this.checkPermission({
      thingGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateThingGroups()
    });
  }

  public canCreateSharepointListColumnToThingMappingItems(
    thingGroup: ThingGroup
  ): boolean {
    return this.checkPermission({
      thingGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateSharepointListColumnToThingMappingItems()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ThingGroup,
    ThingGroup
  > {
    return thingGroupEntityInfo;
  }

  private checkPermission({
    thingGroup,
    checkRolePermission
  }: {
    thingGroup: ThingGroup;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkPermissionBasedOnThingGroupId({
      thingGroupId: thingGroup.id,
      ownerUserGroupId: thingGroup.ownerUserGroupId,
      thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing:
        this.thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ThingGroupAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
